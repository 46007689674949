import React, {Component} from "react";
import '../../../../shared/simos/styling/popupstyle.css';
import {RouteComponentProps} from 'react-router-dom';
import editOrder from '../APICalls/editOrder';
import Popup from '../../../../shared/simos/helper/popupModal';
import { formatDateFromDBFormat, formatDateToDBFormat } from "../../../../shared/simos/helper/dateFormatter";
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {FaPencilAlt} from 'react-icons/fa';
import Radio from '@material-ui/core/Radio';
import Button from '@material-ui/core/Button';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import BackButton from '../../../../shared/simos/helper/backButton';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@mui/material/MenuItem';
import Chip from '@mui/material/Chip';
import editSelectedOrderContacts from '../APICalls/editSelectedOrderedByContact';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import PrintIcon from '@mui/icons-material/Print';
import EmailIcon from '@mui/icons-material/Email';
import getSIMOSTransportCompany from '../APICalls/getRequests/getSIMOSTransportCompany';
import getSIMOSWHProductInfo from '../APICalls/getRequests/getSIMOSWHProductInfo';
import deleteProductOrder from '../APICalls/deleteProductOrder';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import Typography from '@material-ui/core/Typography';
import { validateEditForm, errorStr} from "./formValidation";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import getSIMOSTruckInfo from '../APICalls/getRequests/getSIMOSTruckInfo';
import {getSIMOSUserGroupAccess} from '../APICalls/getSIMOSGroupAccess';
import ProductHistoryPage from "../HistoryPages/ProductHistory/productHistoryPage";
import { savedView } from "./gridView";
import {getAsyncSIMOSCustomerInfoByID} from "../APICalls/getRequests/getSIMOSCustomerInfoByID";
import { getSILURLParams } from "../../../../shared/simos/helper/getURLContainsSIL";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
const StyledTableBorder = {
    border: '2px solid rgba(0, 0, 0, 0.28)',
};
const StyledTableHeader = {
    backgroundColor:'#e3efff'
};

interface IProps extends RouteComponentProps{
    editID: number;
    allRequests:any;
    history:any;
}

interface IState {
    product:any[];
    allPerOptions:string[];
    allSizeOptions:string[];
    all_transports:any[];
    emailCheck:boolean;
    transportCheck:boolean;
    packagedCheck:boolean;
    completedCheck:boolean;
    addProduct:boolean;
    showPopup:boolean;
    contacts:any[];
    orderformName:string;
    orderformEmail:string;
    orderformFax:string;
    orderformPhone:string;
    copyedit:any;
    productedit:any[];
    showAddProduct:boolean;
    isOrderStatusCompleted:boolean;
    showDeletePopup:boolean;
    chosenProd:string;
    chosenSize:string;
    chosenPer:string;
    code:string;
    c_w:string;
    c_w_checkbox:boolean;
    d_c_w:string;
    d_c_w_checkbox:boolean;
    quantity:number;
    actualQuantity:number;
    totalTonnage:number;
    showCancelOrderPopup:boolean;
    cancelOrderConfirm:boolean;
    showError:boolean;
    errorText:string;
    selectedContact:string;
    zone:string;
    hired_truck_rate:number;
    truck_code:string;
    time_in:string;
    time_out:string;
    customer_explorer_code:string;
    collectCheck:boolean;
}
// let newProduct = {quantity:'',size:'',per:'',product:'',code:'',cw:'', actual_Quantity:'', id:0};
let newProduct = {quantity:'',size:'',per:'',product:'',code:'',cw:'',double_CW:'', actual_Quantity:'', id:0};
var deleteIndex = 0;
let idx:number = 0;
let currentitem:any;
var productOptions:any;
var optionTransport;
var chosenCustomer:string;
var chosenDelivery:string;
var chosenCustomerID:number;
var productCascadeDropdown:any[]= [];
var cancelOrderConfirm = false;
var cancelTriggered=false;
var truckInfo:any = [];
var isSuperUser:boolean = false;
var savedEditView:string;
class EditForm extends Component<IProps, IState>{
    constructor(props:IProps) {
        super(props);
        this.state = {
            product:[], allPerOptions:[], allSizeOptions:[],all_transports:[],emailCheck:false,transportCheck:false,packagedCheck:false,completedCheck:false,addProduct:false,showPopup:false,contacts:[],
            orderformName:'',orderformEmail:'',orderformFax:'',orderformPhone:'',copyedit:{},productedit:[],showAddProduct:false, isOrderStatusCompleted:false, showDeletePopup:false,
            chosenSize:'', chosenProd:'', chosenPer:'', code:'',actualQuantity:0,quantity:0,c_w:'', c_w_checkbox:false, d_c_w:'', d_c_w_checkbox:false, totalTonnage:0, showCancelOrderPopup:false, cancelOrderConfirm:false, errorText:'', showError:false,
            selectedContact:'', zone:'', hired_truck_rate:0, truck_code:'', time_in:'', time_out:'', customer_explorer_code:'', collectCheck:false
        }
        this.onTextChange = this.onTextChange.bind(this);
        // this.handleClick = this.handleClick.bind(this);
        this.cancelOrderConfirm = this.cancelOrderConfirm.bind(this);
        this.viewProductVersionHistory = this.viewProductVersionHistory.bind(this);
    }
    handleContactEdit(index: number, event:any) {
        let current_item:any = this.state.contacts;
        switch(event.target.name){
            case 'contactselected':
                current_item[index].contact_selected = !current_item[index].contact_selected;
                if(current_item[index].contact_selected === false){
                    this.setState({selectedContact:''});
                }
                else{
                    this.setState({selectedContact:current_item[index].orderformName});
                }
                break;
            case 'contactname':
                current_item[index].orderformName = event.target.value;
                break;
            case 'contactphone':
                current_item[index].orderformPhone = event.target.value;
                break;
            case 'contactfax':
                current_item[index].orderformFax = event.target.value;
                break;
            case 'contactemail':
                current_item[index].orderformEmail = event.target.value;
                break;
        }
        this.setState({contacts: current_item});
    }
    // Handler for editing a current document
    // Initialization for current_item must occur here due to indexing issues
    async handleEdit(index: number, product_index:number, event:any) {  
        index = this.state.copyedit.id;
        let current_item = this.state.copyedit;
        switch(event.target.name){
            case 'site_contact':
                current_item.sitecontact = event.target.value;
                currentitem = current_item;
                break;
            case 'site_contact_phone':
                current_item.sitecontact_Phone = event.target.value;
                currentitem = current_item;
                break;
            case 'po_num':
                current_item.pO_Number = event.target.value; 
                currentitem = current_item;
                break;
            case 'pickup_date':
                current_item.pickup_Datetime = event.target.value;
                var format= formatDateToDBFormat(event.target.value);
                current_item.pickupdate = format[0];
                current_item.pickuphour = format[1];
                current_item.pickupminute = format[2];
                currentitem = current_item;
                break;
            case 'delivery_date':
                current_item.delivery_Datetime = event.target.value;
                var delivery_format = formatDateToDBFormat(event.target.value);
                current_item.deliverydate = delivery_format[0];
                current_item.deliveryhour = delivery_format[1]
                current_item.deliveryminute = delivery_format[2];
                currentitem = current_item;
                break;
            case 'order_type':
                // If there was a change in the transport company but collect was selected, reset values back to customer pickup
                if(event.target.value === 'collect'){
                    current_item.transport_Company = 'Customer Pickup';
                    current_item.truck_Required = 'Customer Pickup';
                    current_item.emailtransport = false;
                    this.setState({transportCheck:false});
                }
                else{
                    current_item.transport_Company = '';
                    current_item.truck_Required = '';
                }
                current_item.order_Type = event.target.value;
                currentitem = current_item;
                this.setState({});
                break;
            case 'location':
                var all_product_descriptions:string[] = [];
                // this.setState({allSizeOptions:[],code:'', chosenProd:'', chosenSize:'', chosenPer:'', quantity:0, c_w:''});
                this.setState({allSizeOptions:[],code:'', chosenProd:'', chosenSize:'', chosenPer:'', quantity:0, c_w:'', d_c_w:''});
                productCascadeDropdown = await getSIMOSWHProductInfo("","",event.target.value,"");
                productCascadeDropdown[0].map((res:any) => {
                    if(all_product_descriptions.indexOf(res.description)===-1){
                        all_product_descriptions.push(res.description);
                    }
                });
                current_item.location = event.target.value;
                currentitem = current_item;
                this.setState({product:all_product_descriptions.sort()});
                break;
            case 'comments':
                current_item.comments = event.target.value;    
                currentitem = current_item;
                break;
            case 'truck_no':
                current_item.truck_Number = event.target.value;
                currentitem = current_item;
                break;
            case 'trailer_no':
                current_item.trailer_Number = event.target.value;
                currentitem = current_item;
                break;
            case 'tare_weight':
                current_item.tare_Weight = event.target.value;
                currentitem = current_item;
                if(current_item.gross_Weight!=null && current_item.tare_Weight!=null){
                    this.setState({totalTonnage:(Number(current_item.gross_Weight.toLowerCase()) - Number(current_item.tare_Weight.toLowerCase())) / 1000});
                }
                break;
            case 'gross_weight':
                current_item.gross_Weight = event.target.value;
                currentitem = current_item;
                if(currentitem.gross_Weight!=null && currentitem.tare_Weight!=null){
                    this.setState({totalTonnage:(Number(current_item.gross_Weight.toLowerCase()) - Number(current_item.tare_Weight.toLowerCase()))/1000});
                }
                break;
            case 'packaged':
                current_item.packaged = !this.state.packagedCheck;
                currentitem = current_item;
                this.setState({packagedCheck: !this.state.packagedCheck});
                break;
            case 'completed':
                current_item.completed = !this.state.completedCheck;
                currentitem = current_item;
                this.setState({completedCheck: !this.state.completedCheck});
                break;
            case 'emailuser':
                current_item.emailuser = !this.state.emailCheck;
                currentitem = current_item;
                this.setState({emailCheck: !this.state.emailCheck});
                break;
            case 'emailtransport':
                current_item.emailtransport = !this.state.transportCheck;
                currentitem = current_item;
                this.setState({transportCheck: !this.state.transportCheck});
                break;
            case 'transport_company':
                current_item.transport_Company = event.target.value;
                currentitem = current_item;
                this.setState({});
                break;
            case 'truck_required':
                current_item.truck_Required = event.target.value;
                currentitem = current_item;
                break;
            case 'transport_notes':
                current_item.transport_Company_Notes = event.target.value;
                currentitem = current_item;
                break;
            case 'cancelradio':
                current_item.cancelled = event.target.value;
                currentitem = current_item;
                break;
            case 'prod_quantity':
                var toNumber = Number(event.target.value).toFixed(0);
                current_item.product_Order[product_index].quantity = Number(toNumber);
                currentitem = current_item;
                break;
            case 'prod_size':
                current_item.product_Order[product_index].size = event.target.value;
                currentitem = current_item;
                break;
            case 'prod_per':
                let allSizes:string[] = [];
                productCascadeDropdown[0].map((res:any) => {
                    if(res.per === event.target.value){
                        allSizes.push(res.bag_Size);
                    } 
                })
                 this.setState({allSizeOptions:allSizes});
                current_item.product_Order[product_index].per = event.target.value;
                currentitem = current_item;
                break;
            case 'prod_name':
                let allPer:string[] = [];
                productCascadeDropdown = await getSIMOSWHProductInfo(event.target.value, "", "", "");
                productCascadeDropdown[0].map((res:any) => {
                    allPer.push(res.per);
                });
                this.setState({allPerOptions:allPer});
                current_item.product_Order[product_index].product = event.target.value;
                currentitem = current_item;
                break;
            case 'prod_code':
                current_item.product_Order[product_index].code = event.target.value;
                currentitem = current_item;
                break;
            case 'prod_cw':
                if(current_item.product_Order[product_index].cw === "Yes"){
                    current_item.product_Order[product_index].cw = "No";
                }
                else{
                    current_item.product_Order[product_index].cw = "Yes";
                }
                //current_item.product_Order[product_index].cw = event.target.value;
                currentitem = current_item;
                this.setState({});
                break;
            case 'prod_double_CW':
                if(current_item.product_Order[product_index].double_CW === "Yes"){
                    current_item.product_Order[product_index].double_CW = "No";
                }
                else{
                    current_item.product_Order[product_index].double_CW = "Yes";
                }
                //current_item.product_Order[product_index].cw = event.target.value;
                currentitem = current_item;
                this.setState({});
                break;
            case 'prod_actual':
                current_item.product_Order[product_index].actual_Quantity = event.target.value;
                currentitem = current_item;
                break;
            case 'zone':
                current_item.zone = event.target.value;
                currentitem = current_item;
                break;
            case 'hired_truck_rate':
                current_item.hired_Truck_Rates = event.target.value;
                currentitem = current_item;
                break;
            case 'truck_code':
                current_item.truck_Code = event.target.value;
                currentitem = current_item;
                break;
            case 'time_in':
                current_item.time_In = event.target.value;
                currentitem = current_item;
                break;
            case 'time_out':
                current_item.time_Out = event.target.value;
                currentitem = current_item;
                break;
            case 'collect_check':
                this.setState(initialState => ({collectCheck: !initialState.collectCheck}));
                if(!this.state.collectCheck){
                    current_item.transport_Company = 'Customer Pickup';
                    current_item.truck_Required = 'Customer Pickup';
                    current_item.emailtransport = false;
                    current_item.order_Type = "collect";
                    this.setState({transportCheck:false});
                }
                else{
                    current_item.order_Type = "prepaid";
                    current_item.transport_Company = '';
                    current_item.truck_Required = '';
                }
                currentitem = current_item;
                this.setState({});
                break;
            case 'customer_job_number':
                current_item.customer_Job_Number = event.target.value;
                currentitem = current_item;
                break;
        }  
        // Keep track of which item is being changed
        idx=index;
    }

    async onTextChange(event:any) {  
        const fieldname = event.target.name;
        switch(fieldname){ 
            case 'newquantity':
                var toNum = Number(event.target.value).toFixed(0);
                this.setState({quantity:Number(toNum)});
                newProduct.quantity = event.target.value;
                break;
            case 'newsize':
                this.setState({chosenSize:event.target.value});
                newProduct.size = event.target.value;
                if(this.state.copyedit.location.includes("Warehouse")){ 
                    productCascadeDropdown[0].map((res:any) => {
                        if(event.target.value === res.size && this.state.chosenProd === res.description){
                           this.setState({code:res.code});
                        }
                    });
                }
                else{
                    truckInfo.map((trucks:any) => {
                        if(trucks.truck === event.target.value){
                            this.setState({quantity:trucks.size});
                        }
                    });
                }
                break;
            case 'newper':
                newProduct.per = event.target.value;
                this.setState({chosenPer:event.target.value});
                break;
            case 'newproductname':
                let allSizes:string[] = [];
                this.setState({chosenProd:event.target.value, chosenSize:'', chosenPer:'', code:'', allPerOptions:[]});
                //productCascadeDropdown = await getSIMOSWHProductInfo(event.target.value, "", "", "");
                productCascadeDropdown[0].map((res:any) => { 
                        if(res.description === event.target.value){
                            allSizes.push(res.size);   
                        }
                    
                });
                if(this.state.copyedit.location === "Bulk"){
                    var bulkSizes:any = [];
                    this.setState({quantity:0});
                    var response:any = await getSIMOSTruckInfo();
                    if(response[0].status === 200){
                        response[1].map((elem:any) => {
                            bulkSizes.push(elem.truck);
                            truckInfo.push(elem);
                        });
                        productCascadeDropdown[0].map((res:any) => {
                            if(this.state.chosenProd === res.description){
                               this.setState({code:res.code, allSizeOptions:bulkSizes});
                            }
                        });
                    }
                    else{
                        alert("error retreiving bulk truck size: server error");
                    }
                }
                else{
                    this.setState({allSizeOptions:allSizes});   
                }
                newProduct.product = event.target.value;
                break;
            case 'newcode':
                newProduct.code = event.target.value;
                var chosenProd = "";
                let allSize:string[] = [];
                this.setState({code:event.target.value, chosenSize:'', chosenProd:''});
                if(this.state.copyedit.location.includes('Warehouse')){
                    productCascadeDropdown[0].map((res:any) => {
                        if(res.code === event.target.value){
                            chosenProd = res.description;
                            allSize.push(res.size);
                        }
                    });
                this.setState({chosenProd:chosenProd, allSizeOptions:allSize});
                }
               else{
                    var bulkSize:any = [];
                    productCascadeDropdown[0].map((res:any) => {
                        if(res.code === event.target.value){
                            chosenProd = res.description;
                        }
                    });
                    this.setState({chosenProd:chosenProd});
                    var response:any = await getSIMOSTruckInfo();
                    if(response[0].status === 200){
                        response[1].map((elem:any) => {
                            bulkSize.push(elem.truck);
                            truckInfo.push(elem);
                        });
                    }
                    else{
                        alert("error retreiving bulk truck size: server error");
                    }
                    this.setState({allSizeOptions:bulkSize});
                }
                break;
            case 'newcw':
                this.setState({c_w_checkbox: !this.state.c_w_checkbox});
                if(event.target.value === false){
                    newProduct.cw = "No";
                    this.setState({c_w:"No"});
                }
                else{
                    newProduct.cw = "Yes";
                    this.setState({c_w:"Yes"});
                }
                break;
            case 'newdcw':
                this.setState({d_c_w_checkbox: !this.state.d_c_w_checkbox});
                if(event.target.value === false){
                    newProduct.double_CW = "No";
                    this.setState({d_c_w:"No"});
                }
                else{
                    newProduct.double_CW = "Yes";
                    this.setState({d_c_w:"Yes"});
                }
                break;
            case 'newactualquantity':
                this.setState({actualQuantity:event.target.value});
                newProduct.actual_Quantity = event.target.value;
                break;
            case 'newcustomer':
                this.setState({orderformName:event.target.value});
                break;
            case 'newphone':
                this.setState({orderformPhone:event.target.value});
                break;
            case 'newfax':
                this.setState({orderformFax:event.target.value});
                break;
            case 'newemail':
                this.setState({orderformEmail:event.target.value});
                break;
        }
    }
    // Display adding a new product 
    displayAddProd(){
        this.setState({showAddProduct: !this.state.showAddProduct});
    }
    // A new product can be added within editing a form
    handleAddProd(obj: any, index:number, event:any) {
        if(this.state.quantity!=null && this.state.quantity > 0){
            var c_w = this.state.c_w;
            var d_c_w = this.state.d_c_w;
            if(this.state.c_w === ""){
                c_w = "No";
            }
            if(this.state.d_c_w === ""){
                d_c_w = "No";
            }
            this.setState({showAddProduct:false});
            index = this.state.copyedit.id;
            event.preventDefault();
            let current_item = this.state.copyedit;
            // let newProduct = {quantity:this.state.quantity,size:this.state.chosenSize,per:this.state.chosenPer,
            //     product:this.state.chosenProd,code:this.state.code,cw:c_w, actual_Quantity:this.state.actualQuantity, id:0};
            let newProduct = {quantity:this.state.quantity,size:this.state.chosenSize,per:this.state.chosenPer,
                product:this.state.chosenProd,code:this.state.code,cw:c_w,double_CW:d_c_w, actual_Quantity:this.state.actualQuantity, id:0};
            current_item.product_Order.push(newProduct);
            currentitem = current_item;
            idx = index;
            alert("Your changes will be reflected upon save");
            // this.setState({addProduct:true,quantity:0, chosenSize:'',chosenPer:'',chosenProd:'',code:'',c_w:'',actualQuantity:0, c_w_checkbox:false});
            this.setState({addProduct:true,quantity:0, chosenSize:'',chosenPer:'',chosenProd:'',code:'',c_w:'',d_c_w:'',actualQuantity:0, c_w_checkbox:false, d_c_w_checkbox:false});
        }
        else{
          //  this.setState({showAddProduct:false});
          if(this.state.copyedit.location === 'Bulk'){
            alert("Requested quantity must be entered and greater than 0");
        }
        else{
            alert("Quantity must be entered and greater than 0");
        }
        }
    }
    // * Confirm deleting a product
    handleDeleteProdSave(){
        if(this.state.copyedit.product_Order[deleteIndex].id){
            deleteProductOrder(this.state.copyedit.product_Order[deleteIndex]);
            this.state.copyedit.product_Order.splice(deleteIndex,1);
            this.setState({showDeletePopup: !this.state.showDeletePopup});
        }
        else{
            this.state.copyedit.product_Order.splice(deleteIndex,1);
            this.setState({showDeletePopup: !this.state.showDeletePopup});
        }
    }
    handleDeleteProdCancel(){
        this.setState({showDeletePopup: !this.state.showDeletePopup});
    }
    // * On delete product
    handleProductDelete(index:number, event:any){
        deleteIndex = index;
        event.preventDefault();
        this.setState({showDeletePopup: true});
    }

    async fetchOrderedBy(chosenCustomerID:number) {
        let initialContacts:any = [];
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type':'application/json' }, 
            body: JSON.stringify({request:{customer_id:chosenCustomerID, order_id:this.state.copyedit.id}, token: '19515195-d571-44a3-b0db-637f5bf24f54' })
        }
        await fetch('https://api2.propsense.com/api/GetSIMOSOrderedByContact', requestOptions)
        .then(async response => {
            const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
              // error response
              if(!response.ok) {
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            }
            // no error
            data.map((contacts:any) => {
                if(contacts.customer_ID === chosenCustomerID) {
                    initialContacts.push({orderformName: contacts.contact, orderformEmail:contacts.email, orderformFax:contacts.fax, orderformPhone:contacts.phone, 
                    contact_selected:false, contactID: contacts.id, customerID:contacts.customer_ID, deliveryID:contacts.delivery_ID});
                }
            });
        });
        fetch('https://api2.propsense.com/api/GetSIMOSSelectedContactEdit', requestOptions)
        .then(async response => {
            const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
              // error response
              if(!response.ok) {
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            }
            data.map((contact:any) => {
                console.log('order contacts is ', contact)
                // Changed contact.id to contact.orderID
                // if(contact.orderID===this.state.copyedit.id){
                    for(var ind=0;ind<initialContacts.length;ind++){
                        if(initialContacts[ind].orderformName === contact.contact && initialContacts[ind].orderformPhone=== contact.phone
                            &&initialContacts[ind].customerID === contact.customer_ID){
                            initialContacts[ind].contact_selected = true;
                            this.setState({selectedContact:initialContacts[ind].orderformName});
                        }
                    }
               // }
                this.setState({contacts:initialContacts});
            })
        });
        var explorer_code:any = await getAsyncSIMOSCustomerInfoByID(chosenCustomerID);
        console.log(explorer_code)
        this.setState({customer_explorer_code:explorer_code});
    }

    async componentDidMount() {
        savedEditView = savedView;
        var response:any = await getSIMOSUserGroupAccess();
        var userRole:any = response.role;
        isSuperUser = userRole === 'SuperAdmin';
        if(userRole!='Admin' && userRole!='Dispatcher' && userRole!='SuperAdmin'){
            this.props.history.push('/simos');
        }
        this.setState({all_transports: await getSIMOSTransportCompany()});
      // ! Get order by ID
      const orderOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ request:{
                Customer:'orderdesk',
                ID:window.location.pathname.split("=")[1].split("?")[0]
            },
                token: '19515195-d571-44a3-b0db-637f5bf24f54' })
        };
       var editdata:any=[];
       await fetch('https://api2.propsense.com/api/GetSIMOSOrderByID', orderOptions)
       .then(async response => {
            const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
            // error response
            if(!response.ok) {
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            }
            // no error
            editdata = data;
            // Disabled save button if order status is complete
            if(editdata.status==="Completed"){
                this.setState({isOrderStatusCompleted:true});
            }
            this.setState({copyedit:editdata});
            this.fetchOrderedBy(this.state.copyedit.customer_ID);

            })
            .catch(error => {
                console.log('There was an error!', error);
            });
            // ! Get orderedby products
            var editproducts:any=[];
            
            await fetch('https://api2.propsense.com/api/GetSIMOSProduct', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                token: '19515195-d571-44a3-b0db-637f5bf24f54' })
            })
            .then(async response => {
            const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
            // error response
            if(!response.ok) {
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            }
            // no error
            data.map((products:any) => {
                if(products.orderID.toString() === window.location.pathname.split("=")[1].split("?")[0]){
                editproducts.push(products);
                }
            });
                this.setState(state => ({copyedit:
                    {
                        ...state.copyedit,
                        product_Order:editproducts,
                    },
                }))
                console.log(this.state.copyedit.product_Order);
            })
            .catch(error => {
                console.log('There was an error!', error);
            })
            if(this.state.copyedit.order_Type === 'collect'){
                this.setState({collectCheck:true});
            }
            else{
                this.setState({collectCheck:false});
            }
            this.setState({completedCheck: this.state.copyedit.completed==='Yes', packagedCheck: this.state.copyedit.packaged==='Yes', 
            emailCheck: this.state.copyedit.emailUser==='Yes', transportCheck: this.state.copyedit.emailTransport==='Yes'});
            var all_products:string[] = [];
            productCascadeDropdown = await getSIMOSWHProductInfo("","",this.state.copyedit.location,"");
            productCascadeDropdown[0].map((res:any) => {
                if(all_products.indexOf(res.description)===-1){
                    all_products.push(res.description);
                }
            });
            this.setState({product:all_products.sort(), totalTonnage:this.state.copyedit.total_Tonnage});
    }

    handlePopupCancel(){
        this.setState({showPopup:!this.state.showPopup});
    }
    handlePopupExit(){
        this.props.history.push('/simos/orders'+getSILURLParams());
    }
    viewProductVersionHistory(index:number){
        //this.props.history.push('/simos/history/products/id='+index);
        window.open("/simos/history/products/id="+index+getSILURLParams(), "_blank");
        return <ProductHistoryPage history={this.props.history}></ProductHistoryPage>
    }

    cancelEdit(event:any) {
        event.preventDefault();
        this.setState({showPopup:!this.state.showPopup});
    }
    async cancelOrderConfirm(){
        var res:any = await editOrder(currentitem);
        if(res.status === 200){
            this.props.history.replace('/simos/orders'+getSILURLParams());
            //window.location.reload();
        }
        else{
            alert("Failed to update order: server/db error");
        }
    }

    // On form complete
    async saveEdit(event:any){
        event.preventDefault();
        var isCodeFilled = true;
        if(currentitem != null){
            if(validateEditForm(currentitem, this.state.contacts)){
                // Collect orders
                if(currentitem.prepaid === 'collect'){
                    // Check if po number and transport company are filled
                 //   if(currentitem.pO_Number != "" && currentitem.transport_Company != "TBD"){
                        // Check if product codes were assigned
                        for(var idx=0; idx<currentitem.product_Order.length; idx ++){
                            if(currentitem.product_Order[idx].code ===""){
                                this.setState({showError:true, errorText:'Product code must be entered for the order to be assigned!'});
                                isCodeFilled = false;
                            }
                        }
                        if(isCodeFilled){
                            for(var ind=0;ind<this.state.contacts.length;ind++){
                                if(this.state.contacts[ind].contact_selected === true){
                                    editSelectedOrderContacts(this.state.contacts[ind], this.state.copyedit.id, chosenCustomerID);
                                }
                            }
                            // Ready for pickup orders that are ready to be completed must have truck, trailer, gross and tare fields filled if completed is checked
                            if(currentitem.status === 'Ready for pickup' && currentitem.packaged === 'Yes'  && (currentitem.completed === true || currentitem.completed ==="Yes")){
                                // Check if all actual quantities are filled
                                var isQuantityFilled = true;
                                if(currentitem.tare_Weight !="" && currentitem.gross_Weight !="" && currentitem.trailer_Number !="" && currentitem.truck_Number != ""){
                                    for(var idx=0; idx<currentitem.product_Order.length; idx ++){
                                        if(Number(currentitem.product_Order[idx].actual_Quantity) ===0 || Number(currentitem.product_Order[idx].actual_Quantity)===null){
                                            isQuantityFilled = false;
                                            this.setState({showError:true, errorText:'Completed orders must have an actual quantity!'});
                                        }
                                    }
                                    if(this.state.totalTonnage>0 && isQuantityFilled){
                                            currentitem.total_Tonnage = this.state.totalTonnage;
                                            var res:any = await editOrder(currentitem);
                                            if(res.status === 200){
                                                this.props.history.replace('/simos/orders'+getSILURLParams());
                                                // ! switch with setstate or refresh grid
                                                //window.location.reload();
                                            }
                                            else{
                                                alert("Failed to update order: server/db error");
                                            }
                                        }
                                    else{
                                        if(isQuantityFilled === false){
                                            this.setState({showError:true, errorText:'Completed orders must have an actual quantity!'});
                                        }
                                        else{
                                            this.setState({showError:true, errorText:'Total tonnage must be greater than 0!'});
                                        }
                                    }
                                }
                                else{
                                    this.setState({showError:true, errorText:'Gross, tare, truck and trailer numbers must be entered if the order is complete!'});
                                }
                            }
                            // Any other order status allows empty truck, trailer, gross and tare weight to be empty
                            else{
                                var isTonnageCorrect = true;
                                // if both gross and tare are entered set total tonnage
                                if(currentitem.gross_Weight >0 && currentitem.tare_Weight > 0){
                                    if(this.state.totalTonnage > 0){
                                        currentitem.total_Tonnage = this.state.totalTonnage;
                                    }
                                    else{
                                        isTonnageCorrect = false;
                                    }
                                }
                                if(isTonnageCorrect){
                                    var res:any = await editOrder(currentitem);
                                    if(res.status === 200){
                                        this.props.history.replace('/simos/orders'+getSILURLParams());
                                        // ! switch with setstate or refresh grid
                                        //window.location.reload();
                                    }
                                    else{
                                        alert("Failed to update order: server/db error");
                                    }
                                }
                                else{
                                    this.setState({showError:true, errorText:'Total tonnage must be > 0 if gross and tare weight are entered!'});
                                }
                            }
                        }
                }
                // Order is a prepaid order
                else{
                        // Check if product codes were assigned
                        for(var idx=0; idx<currentitem.product_Order.length; idx ++){
                            if(currentitem.product_Order[idx].code ===""){
                                this.setState({showError:true, errorText:'Product code must be entered for the order to be assigned!'});
                                isCodeFilled = false;
                            }
                            
                        }
                        if(isCodeFilled){
                            for(var ind=0;ind<this.state.contacts.length;ind++){
                                if(this.state.contacts[ind].contact_selected === true){
                                    editSelectedOrderContacts(this.state.contacts[ind], this.state.copyedit.id, chosenCustomerID);
                                }
                            }
                            // Ready for pickup orders that are ready to be completed must have truck, trailer, gross and tare fields filled if completed is checked
                            if(currentitem.status === 'Ready for pickup' && currentitem.packaged === 'Yes' && (currentitem.completed === true || currentitem.completed ==="Yes")){
                               // Check if all actual quantities are filled
                                var isQuantityFilled = true;
                                   if(currentitem.tare_Weight !="" && currentitem.gross_Weight !="" && currentitem.trailer_Number !="" && currentitem.truck_Number != ""){
                                        for(var idx=0; idx<currentitem.product_Order.length; idx ++){
                                            if(Number(currentitem.product_Order[idx].actual_Quantity) ===0 || Number(currentitem.product_Order[idx].actual_Quantity)===null){
                                                isQuantityFilled = false;
                                                this.setState({showError:true, errorText:'Completed orders must have an actual quantity!'});
                                            }
                                        }    
                                        if(this.state.totalTonnage>0 && isQuantityFilled){
                                            currentitem.total_Tonnage = this.state.totalTonnage;
                                            var res:any = await editOrder(currentitem);
                                            if(res.status === 200){
                                                this.props.history.replace('/simos/orders'+getSILURLParams());
                                                // ! switch with setstate or refresh grid
                                                //window.location.reload();
                                            }
                                            else{
                                                alert("Failed to update order: server/db error");
                                            }
                                        }
                                        else{
                                            if(isQuantityFilled === false){
                                                this.setState({showError:true, errorText:'Completed orders must have an actual quantity!'});
                                            }
                                            else{
                                                this.setState({showError:true, errorText:'Total tonnage must be greater than 0!'});
                                            }
                                        }
                                    }
                                    else{
                                        this.setState({showError:true, errorText:'Gross, tare, truck and trailer numbers must be entered if the order is complete!'});
                                    }
                                }
                                // Any other edit besides checking completed
                                else{
                                    var isTonnageCorrect = true;
                                    // if both gross and tare are entered set total tonnage
                                    if(currentitem.gross_Weight >0 && currentitem.tare_Weight > 0){
                                        if(this.state.totalTonnage > 0){
                                            currentitem.total_Tonnage = this.state.totalTonnage;
                                        }
                                        else{
                                            isTonnageCorrect = false;
                                        }
                                    }
                                    
                                    if(isTonnageCorrect){
                                        var res:any = await editOrder(currentitem);
                                        if(res.status === 200){
                                            this.props.history.replace('/simos/orders'+getSILURLParams());
                                            // ! switch with setstate or refresh grid
                                            //window.location.reload();
                                        }
                                        else{
                                            alert("Failed to update order: server/db error");
                                        }
                                    }
                                    else{
                                        this.setState({showError:true, errorText:'Total tonnage must be > 0 if gross and tare weight are entered!'});
                                    }
                                }
                        }
                }
            }
            else{
                this.setState({showError:true, errorText:errorStr});
            }
        }
    
        else{
            //alert("Changes must be made to be able to edit!");
            this.setState({showError:true, errorText:'Changes must be made to be able to edit!'});
        }
    }

    render() {
        let products = this.state.product.sort();
        productOptions = products.map((prod) => <option>{prod}</option>);
        productOptions.push(<option disabled selected></option>);

        let transports = this.state.all_transports;
        optionTransport = transports.map((company) => <option>{company}</option>);

        let allperoptions:any[] = this.state.allPerOptions.map((per) => <option>{per}</option>);
        allperoptions.push(<option disabled selected></option>);
        let allsizeoptions:any[] = this.state.allSizeOptions.map((sizes) => <option>{sizes}</option>);
        allsizeoptions.push(<option disabled selected></option>);

        let customerContactOptions = this.state.contacts.map((elem:any, idx:number) => 
            <MenuItem value={elem.orderformName}>
                <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: 0.1 }}>
                    <Checkbox disabled={this.state.copyedit.status==='Completed'} checked={elem.contact_selected} onChange={this.handleContactEdit.bind(this, idx)} name="contactselected"></Checkbox>
                    <Grid item xs={3}>
                        <Chip icon={<PermContactCalendarIcon/>} label={elem.orderformName}></Chip>
                    </Grid>
                    <Grid item xs={3}>
                        <Chip icon={<LocalPhoneIcon/>} label={elem.orderformPhone}></Chip>
                    </Grid>
                    <Grid item xs={3}>
                        <Chip icon={<PrintIcon />} label={elem.orderformFax}></Chip>
                    </Grid>
                    <Grid item xs={3}>
                        <Chip icon={<EmailIcon/>} label={elem.orderformEmail}></Chip>
                    </Grid>
                </Box>
            </MenuItem>
        );

        if(this.state.copyedit.id != null && this.state.copyedit.product_Order != null){
            var formatPickupDate = formatDateFromDBFormat(this.state.copyedit.pickupdate, this.state.copyedit.pickuphour, this.state.copyedit.pickupminute);
            var formatDeliveryDate = formatDateFromDBFormat(this.state.copyedit.deliverydate, this.state.copyedit.deliveryhour, this.state.copyedit.deliveryminute);
            chosenCustomer = this.state.copyedit.customer;
            chosenCustomerID=this.state.copyedit.customer_ID;
            chosenDelivery = this.state.copyedit.delivery;
        return (
            <Paper className={getSILURLParams()?.includes("bru") ? "paper-popup":"paper-popup-sil-ed"}>
                <Paper className="paper-popup-inner">
            <BackButton goBack={this.cancelEdit.bind(this)} history={this.props.history}></BackButton>
                    <form>
                    
                    <div>
                        <h3>Editing {getSILURLParams()?.includes("bru") ? "SIL BRU":"SIL ED"} Order <label id='header-3-label'>#{this.state.copyedit.id}</label></h3>
                                    <Paper className="paper-card">
                                    <div id="customer-deliver">
                                        Customer: <label id='edit-customer-name-label'>{this.state.copyedit.customer}{this.state.customer_explorer_code != null? "("+this.state.customer_explorer_code+")":null}</label>
                                        <br></br> 
                                        {/* Order Type: <label  id='edit-delivery-name-label'>{this.state.copyedit.order_Type}</label><br></br> */}
                                        Delivery Address: <label  id='edit-delivery-name-label'>{this.state.copyedit.address}</label>
                                        <br></br>
                                        {this.state.copyedit.sales_Order_Number!=null && this.state.copyedit.sales_Order_Number!=""? <>Sales Order #:<label  id='edit-delivery-name-label'>{this.state.copyedit.sales_Order_Number}</label></>:null}
                                    </div>
                                    <br></br>
                               
                                <Paper className="paper-card">
                                    <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Typography style={{fontWeight:"bold"}} variant="h5" gutterBottom component="div">Select a Customer Contact</Typography>
                                            <Select 
                                            variant="outlined"
                                            fullWidth
                                            name="customer_contact_select"
                                            value={this.state.selectedContact}
                                            onChange={this.onTextChange}
                                            disabled={this.state.copyedit.status==='Completed'}
                                            >
                                                {customerContactOptions}
                                            </Select>
                                    
                                        </Grid>
                                      
                                        <Grid item xs={4}>
                                        <InputLabel htmlFor="datetime-local">Scheduled Pickup Date/Time</InputLabel>
                                        <TextField disabled={this.state.copyedit.status==='Completed'} id='datetime-local' type='datetime-local' name="pickup_date" defaultValue={formatPickupDate}onChange={this.handleEdit.bind(this, this.state.copyedit.id, null) } InputLabelProps={{shrink:true}}></TextField>
                                        {/* <label>Pickup Date</label><input id='edit-form-input-field' type="date" name="pickup_date" onChange={this.handleEdit.bind(this, this.props.editID, null) }defaultValue={obj.pickupDate}placeholder={obj.pickupDate}></input>
                                        <label> Hour:</label><select id='edit-form-input-field' name="pickup_hour" onChange={this.handleEdit.bind(this, this.props.editID, null) }defaultValue={obj.pickupHour}placeholder={obj.pickupHour}>{optionItems}</select>
                                        <label> Min:</label><select id='edit-form-input-field' name = "pickup_min" onChange={this.handleEdit.bind(this, this.props.editID, null) }defaultValue={obj.pickupMin}placeholder={obj.pickupMin}>{optionItems2}</select><br></br>
                                        <br></br> */}
                                        </Grid>
                                        
                                        <Grid item xs={4}>
                                        <InputLabel htmlFor="datetime-local">Delivery Date/Time</InputLabel>
                                        <TextField disabled={this.state.copyedit.status==='Completed'} id='datetime-local' type='datetime-local' name="delivery_date" defaultValue={formatDeliveryDate}onChange={this.handleEdit.bind(this, this.state.copyedit.id, null) } InputLabelProps={{shrink:true}}></TextField>
                                        {/* <label>Delivery Date</label><input id='edit-form-input-field' type="date" name="delivery_date" onChange={this.handleEdit.bind(this, this.props.editID, null) }defaultValue={obj.deliveryDate}placeholder={obj.deliveryDate}></input>
                                        <label> Hour:</label><select id='edit-form-input-field' name="delivery_hour" onChange={this.handleEdit.bind(this, this.props.editID, null) }defaultValue={obj.deliveryHour}placeholder={obj.deliveryHour}>{optionItems}</select>
                                        <label> Min:</label><select id='edit-form-input-field' name ="delivery_min" onChange={this.handleEdit.bind(this, this.props.editID, null) }defaultValue={obj.deliverMin}placeholder={obj.deliveryMin}>{optionItems2}</select> */}
                                        </Grid>

                                        {/* <Grid item xs={4}>
                                        <FormLabel style={{fontWeight:'bold'}} component="legend">Order Type</FormLabel>
                                            <RadioGroup row defaultValue={this.state.copyedit.order_Type} onChange={this.handleEdit.bind(this, this.state.copyedit.id, null)} name='order_type'>
                                                <FormControlLabel value="prepaid" control={<Radio disabled={this.state.copyedit.status==='Completed'}/>} label="Prepaid"/>
                                                <FormControlLabel value="collect" control={<Radio disabled={this.state.copyedit.status==='Completed'}/>} label="Collect"/>
                                            </RadioGroup>
                                        </Grid> */}
                                        <Grid item xs={3}>
                                            <FormControlLabel control={
                                                            <Checkbox disabled={this.state.copyedit.status==='Completed'} checked={this.state.collectCheck} onChange={this.handleEdit.bind(this, this.state.copyedit.id, null) } name="collect_check" color="primary"/>
                                                } 
                                                label="Collect"
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField disabled={this.state.copyedit.status==='Completed'} autoComplete="nope" label="Site Contact" defaultValue={this.state.copyedit.sitecontact} name='site_contact' onChange={this.handleEdit.bind(this, this.state.copyedit.id, null) }></TextField>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField disabled={this.state.copyedit.status==='Completed'} autoComplete="nope" label="Site Contact Phone #" defaultValue={this.state.copyedit.sitecontact_Phone} name='site_contact_phone' onChange={this.handleEdit.bind(this, this.state.copyedit.id, null) }></TextField>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField disabled={this.state.copyedit.status==='Completed'} autoComplete="nope" required label="PO #" defaultValue={this.state.copyedit.pO_Number} name='po_num' onChange={this.handleEdit.bind(this, this.state.copyedit.id, null) }></TextField>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField disabled={this.state.copyedit.status==='Completed'} autoComplete="nope" required label="Customer Job #" defaultValue={this.state.copyedit.customer_Job_Number} name='customer_job_number' onChange={this.handleEdit.bind(this, this.state.copyedit.id, null) }></TextField>
                                        </Grid>
                              
                                       <Grid item xs={3}>
                                        <FormControl>
                                        {this.state.copyedit.order_Type === 'prepaid' ? 
                                        <div>
                                        {/* <TextField autoComplete='off' defaultValue={this.state.copyedit.transport_Company} id="standard-required" name="transport_company" label="Transport Company" onChange={this.handleEdit.bind(this, this.state.copyedit.id, null) } ></TextField> */}
                                        <Select disabled={this.state.copyedit.status==='Completed'} native defaultValue= {this.state.copyedit.transport_Company} onChange={this.handleEdit.bind(this, this.state.copyedit.id, null) } inputProps={{name:'transport_company', id:'transport-native-simple'}}>
                                            {/* <option selected disabled>{this.state.copyedit.transport_Company}</option> */}
                                            {optionTransport}
                                            </Select>
                                        </div>:<TextField disabled label="Transport Company" defaultValue="Customer Pickup"></TextField>}
                                        </FormControl>
                                        </Grid>
                              
                                    <Grid item xs={3}>
                                    {this.state.copyedit.order_Type === 'prepaid' ? 
                                        <FormControl>
                                     
                                        <InputLabel htmlFor="truckreq-native-simple">Truck Size</InputLabel>
                                        <Select disabled={this.state.copyedit.status==='Completed'} native defaultValue = {this.state.copyedit.truck_Required} onChange={this.handleEdit.bind(this, this.state.copyedit.id, null) } inputProps={{name:'truck_required', id:'truckreq-native-simple'}}>
                                            <option>Single Bulk</option>
                                            <option>Single Deck</option>
                                            <option>BTrain Bulk</option>
                                            <option>BTrain Deck</option>
                                            <option>Super BTrain Bulk</option>
                                            <option>Super BTrain Deck</option>
                                            <option>Truck and Pup</option>
                                            <option>Tandem</option>
                                            <option>End Dump</option>
                                            <option>Truck and Wagon</option>
                                            <option>Triaxle Deck</option>
                                            <option>Triaxle Bulk</option>
                                            <option>Customer Pickup</option>
                                        </Select>
                                        </FormControl>:<><InputLabel htmlFor="transport-native-default">Truck Size</InputLabel>
                                        <TextField disabled defaultValue="Customer Pickup">
                                    </TextField></>}
                                    </Grid>
                                    {this.state.copyedit.order_Type === 'collect' ?
                                    <Grid item xs={3}>
                                        <TextField autoComplete='off' value = {this.state.copyedit.transport_Company_Notes} multiline rows={2} variant="outlined"  id="standard-required" name="transport_notes" label="Transport Company/Truck Size Notes"  onChange={this.handleEdit.bind(this, this.state.copyedit.id, null) } ></TextField>
                                    </Grid>
                                    :null}
                                        <Grid item xs={3}>
                                       <TextField disabled={this.state.copyedit.status==='Completed'} autoComplete="nope" label="Zone" defaultValue={this.state.copyedit.zone} name='zone' onChange={this.handleEdit.bind(this, this.state.copyedit.id, null) }></TextField>
                                   </Grid>
                                        {/* <Grid item xs={3}>
                                       <TextField disabled={this.state.copyedit.status==='Completed'} type="number" autoComplete="nope" required={this.state.copyedit.order_Type==='collect'} label="Hired Truck Rate" defaultValue={this.state.copyedit.hired_Truck_Rates} name='hired_truck_rate' onChange={this.handleEdit.bind(this, this.state.copyedit.id, null) }></TextField>
                                   </Grid> */}
                                   {this.state.copyedit.transport_Company === 'Yellowhead Trucking' && this.state.copyedit.order_Type==='prepaid' ?   
                                    <Grid item xs={3}>
                                        <TextField autoComplete='off' disabled={this.state.copyedit.status==='Completed'} required name="truck_code" label="Truck Code" defaultValue={this.state.copyedit.truck_Code} onChange={this.handleEdit.bind(this, this.state.copyedit.id, null) }></TextField>
                                        </Grid>:null}
                                    <Grid item xs={3}>
                                            <FormControlLabel control={
                                                <Checkbox checked={this.state.emailCheck} onChange={this.handleEdit.bind(this, this.state.copyedit.id, null) } name="emailuser" color="primary"/>
                                                }               
                                                label={"Email User"}
                                            />
                                        </Grid>
                                        {this.state.copyedit.order_Type === 'prepaid' ?
                                        <Grid item xs={6}>
                                            <FormControlLabel control={
                                                <Checkbox checked={this.state.transportCheck} onChange={this.handleEdit.bind(this, this.state.copyedit.id, null) } name="emailtransport" color="primary"/>
                                                }               
                                                label={"Email Transport Company"}
                                            />
                                        </Grid>
                                        :null}
                                    </Grid>
                                    </Paper>
                                    <br></br>
                                    <TableContainer style={StyledTableBorder} component={Paper}>
                                        <Typography style={{fontWeight:"bold"}} variant="h5" gutterBottom component="div">Products</Typography>
                                        <FormLabel style={{fontWeight:'bold'}} component="legend">Location</FormLabel>
                                            <RadioGroup row defaultValue={this.state.copyedit.location} onChange={this.handleEdit.bind(this, this.state.copyedit.id, null)} name='location'>
                                                {window.location.href.includes("sil_ed") ? 
                                                        <FormControlLabel value="SIL ED Warehouse" control={<Radio disabled={this.state.copyedit.status==='Completed'}/>} label="SIL Ed Warehouse"/>
                                                        :
                                                        <FormControlLabel value="SIL BRU Warehouse" control={<Radio disabled={this.state.copyedit.status==='Completed'}/>} label="SIL Bru Warehouse"/>}
                                                <FormControlLabel value="Bulk" control={<Radio disabled={this.state.copyedit.status==='Completed'}/>} label="Bulk"/>
                                            </RadioGroup>
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                        <TableRow>
                                            <TableCell style={StyledTableHeader}>Product</TableCell>
                                            <TableCell style={StyledTableHeader}>Size</TableCell>
                                            {this.state.copyedit.location.includes("Warehouse") ?
                                            <>
                                            <TableCell style={StyledTableHeader}>Per</TableCell>
                                            <TableCell style={StyledTableHeader}> # of Pallets</TableCell>
                                            </>
                                            :<TableCell style={StyledTableHeader}>Requested Quantity(MT)</TableCell>}
                                            <TableCell style={StyledTableHeader}>Product Code</TableCell>
                                            {this.state.copyedit.location.includes("Warehouse") ?
                                            <>
                                            <TableCell style={StyledTableHeader}>C&W</TableCell>
                                            <TableCell style={StyledTableHeader}>Double C&W</TableCell>
                                            <TableCell style={StyledTableHeader}>Actual Quantity</TableCell>
                                            </>
                                            :<TableCell style={StyledTableHeader}>Actual Quantity(MT)</TableCell>}
                                            <TableCell style={StyledTableHeader}>Batch #</TableCell>
                                            {/* <TableCell style={StyledTableHeader}>Status</TableCell> */}
                                            <TableCell style={StyledTableHeader}>Actions</TableCell>
                                            {isSuperUser ? <TableCell style={StyledTableHeader}>Version History</TableCell>:null}
                                        </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.copyedit.product_Order.map((product:any, index:number) => {
                                                var isProductReady = false;
                                                if(product.status === 'Ready for Pickup' || this.state.copyedit.status === 'Ready for pickup' || this.state.copyedit.status === 'Completed'){
                                                    isProductReady = true;
                                                }
                                                return  <StyledTableRow>
                                                        <TableCell>{product.product}</TableCell>
                                                        <TableCell>{product.size}</TableCell>
                                                        {this.state.copyedit.location.includes("Warehouse") ?
                                                        <TableCell>{product.per}</TableCell>
                                                        :null}
                                                        <TableCell><TextField autoComplete="nope" disabled={isProductReady} type="number" variant="outlined" name="prod_quantity" onChange={this.handleEdit.bind(this, this.state.copyedit.id, index) } defaultValue={product.quantity?Number(product.quantity).toFixed(0):product.quantity}></TextField></TableCell>
                                                        <TableCell><TextField autoComplete="nope" disabled={isProductReady} variant="outlined" name="prod_code" onChange={this.handleEdit.bind(this, this.state.copyedit.id, index)} defaultValue={product.code}></TextField></TableCell>
                                                        {this.state.copyedit.location.includes("Warehouse")?
                                                        <TableCell><Checkbox disabled={isProductReady} name="prod_cw" onChange={this.handleEdit.bind(this, this.state.copyedit.id, index)} checked={product.cw ==="Yes"}></Checkbox></TableCell>
                                                        :null}
                                                        {this.state.copyedit.location.includes("Warehouse")?
                                                        <TableCell><Checkbox disabled={isProductReady} name="prod_double_CW" onChange={this.handleEdit.bind(this, this.state.copyedit.id, index)} checked={product.double_CW ==="Yes"}></Checkbox></TableCell>
                                                        :null}
                                                        <TableCell><TextField disabled={this.state.copyedit.status==='Completed'} autoComplete="nope" name="prod_actual" type="number" variant="outlined" onChange={this.handleEdit.bind(this, this.state.copyedit.id, index)} defaultValue={product.actual_Quantity}></TextField></TableCell>
                                                        {/* <TableCell>{product.status}</TableCell> */}
                                                        <TableCell><TextField multiline autoComplete="nope" disabled variant="outlined" defaultValue={product.batch}></TextField></TableCell>
                                                        <TableCell><Button disabled={this.state.copyedit.status!="Assigned"} onClick={this.handleProductDelete.bind(this, index)} className="edit-del-product-btn">X</Button></TableCell>
                                                        {isSuperUser ?<TableCell><Button className="back-btn" onClick={(e:any) => this.viewProductVersionHistory(product.id)}>View</Button></TableCell>:null}
                                                        {this.state.showDeletePopup? <Popup headerText="Deleting Product" text='Are you sure you want to delete this product?' savePopup = {this.handleDeleteProdSave.bind(this)} closePopup={this.handleDeleteProdCancel.bind(this)}/>:null}

                                                    </StyledTableRow>
                                            })}
                                        </TableBody>
                                        {this.state.showAddProduct === true?
                                            <TableBody>
                                                <TableCell><Select native name="newproductname" value={this.state.chosenProd} onChange={this.onTextChange.bind(this)}>{productOptions}</Select></TableCell>
                                                <TableCell><Select native name="newsize" value={this.state.chosenSize} onChange={this.onTextChange.bind(this)}>{allsizeoptions}</Select></TableCell>
                                                {this.state.copyedit.location.includes("Warehouse")?
                                                <TableCell><TextField autoComplete="nope" type="number" name="newper" value={this.state.chosenPer} onChange={this.onTextChange.bind(this)}></TextField></TableCell>
                                                :null}
                                                <TableCell><TextField autoComplete="nope" type="number" name="newquantity" value={this.state.quantity} onChange={this.onTextChange.bind(this)}></TextField></TableCell>
                                                <TableCell><TextField autoComplete="nope" name="newcode" value={this.state.code} onChange={this.onTextChange.bind(this)}></TextField></TableCell>
                                                {this.state.copyedit.location.includes("Warehouse")?
                                                <TableCell>
                                                <Checkbox checked={this.state.c_w_checkbox} onChange={this.onTextChange.bind(this)}  name="newcw"></Checkbox>
                                                    {/* <Select native name="newcw" value={this.state.c_w} onChange={this.onTextChange.bind(this)}> 
                                                    <option disabled selected></option>
                                                    <option>Yes</option>
                                                    <option>No</option> </Select> */}
                                                </TableCell>
                                                :null}
                                                {this.state.copyedit.location.includes("Warehouse")?
                                                <TableCell>
                                                <Checkbox checked={this.state.d_c_w_checkbox} onChange={this.onTextChange.bind(this)}  name="newdcw"></Checkbox>
                                                </TableCell>
                                                :null}
                                                {/* <TableCell><TextField disabled value={this.state.quantity * Number(this.state.chosenPer)}></TextField></TableCell> */}
                                                <TableCell><TextField autoComplete="nope" type="number" name="newactualquantity" value={this.state.actualQuantity} onChange={this.onTextChange.bind(this)} ></TextField></TableCell>
                                                <TableCell><Button onClick={this.displayAddProd.bind(this)} className="cancel-add-product-btn">Cancel</Button></TableCell>
                                                <TableCell><Button onClick={this.handleAddProd.bind(this, this.state.copyedit, this.state.copyedit.id)} id="add-product-btn">Save</Button></TableCell>
                                            </TableBody>
                                            :<Button disabled={this.state.copyedit.status==='Ready for pickup' || this.state.copyedit.status==='Completed'} onClick={this.displayAddProd.bind(this)} id="add-product-btn">Add Row</Button>}
                                        </Table>
                                    </TableContainer>
                                    <br></br>
                                    <Paper className="paper-card">
                                    <Grid container spacing={3}>
                                        <Grid item xs={6}>
                                        <FaPencilAlt/><TextField className="commentsEditForm" autoComplete="nope" label="Comments" name="comments" fullWidth multiline rows={7}  onChange={this.handleEdit.bind(this, this.state.copyedit.id, null) } defaultValue={this.state.copyedit.comments} variant="outlined"></TextField>
                                        </Grid>
                                        {/* {this.state.copyedit.status != 'Completed' ?<> */}
                                        <Grid item xs={3}>
                                            <FormControlLabel control={
                                                <Checkbox disabled={this.state.copyedit.status==='Completed'} checked={this.state.packagedCheck} onChange={this.handleEdit.bind(this, this.state.copyedit.id, null) } name="packaged" color="primary"/>
                                                }               
                                                label={this.state.copyedit.location.includes("Warehouse")? "Packaged":"In Bin"}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <FormControlLabel control={
                                                <Checkbox disabled={this.state.copyedit.status==='Completed'} checked={this.state.completedCheck} onChange={this.handleEdit.bind(this, this.state.copyedit.id, null) } name="completed" color="primary"/>
                                                } 
                                                label="Completed"
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField disabled={this.state.copyedit.status==='Completed'} autoComplete="nope" label="Truck #" name="truck_no" onChange={this.handleEdit.bind(this, this.state.copyedit.id, null)} defaultValue={this.state.copyedit.truck_Number}></TextField>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField disabled={this.state.copyedit.status==='Completed'} autoComplete="nope" label="Trailer #" name="trailer_no" onChange={this.handleEdit.bind(this, this.state.copyedit.id, null)} defaultValue={this.state.copyedit.trailer_Number}></TextField>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField disabled={this.state.copyedit.status==='Completed'} autoComplete="nope" label="Tare Weight (kg)" type="number" name="tare_weight" onChange={this.handleEdit.bind(this, this.state.copyedit.id, null)} defaultValue={this.state.copyedit.tare_Weight}></TextField>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField disabled={this.state.copyedit.status==='Completed'} autoComplete="nope" label="Gross Weight (kg)" type="number" name="gross_weight" onChange={this.handleEdit.bind(this, this.state.copyedit.id, null)} defaultValue={this.state.copyedit.gross_Weight}></TextField>
                                        </Grid>
                                        <Grid item xs={3}>
                                        <TextField disabled label="Total Tonnage (tonnes)" value={this.state.copyedit.total_Tonnage ? this.state.copyedit.total_Tonnage: this.state.totalTonnage ?this.state.totalTonnage:0}></TextField>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <InputLabel htmlFor="datetime-local">Time In</InputLabel>
                                            <TextField disabled={this.state.copyedit.status==='Completed'} type='datetime-local' autoComplete="nope" name="time_in" onChange={this.handleEdit.bind(this, this.state.copyedit.id, null)} defaultValue={this.state.copyedit.time_In}></TextField>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <InputLabel htmlFor="datetime-local">Time Out</InputLabel>
                                            <TextField disabled={this.state.copyedit.status==='Completed'} type='datetime-local' autoComplete="nope" name="time_out" onChange={this.handleEdit.bind(this, this.state.copyedit.id, null)} defaultValue={this.state.copyedit.time_Out}></TextField>
                                        </Grid>
                                        </Grid>
                                    </Paper>
                                    <br></br>
                                    <Grid container>
                                    <Grid item xs={3} >
                                        <TextField disabled value={this.state.copyedit.created.slice(0,this.state.copyedit.created.lastIndexOf(":")).replace("T", " ")} label="Created"></TextField>
                                        </Grid>
                                        <Grid item xs={3} >
                                        <TextField disabled value={this.state.copyedit.created_By} label="Created By"></TextField>
                                        </Grid>
                                        <Grid item xs={3} >
                                        <TextField disabled value={this.state.copyedit.modified.slice(0,this.state.copyedit.created.lastIndexOf(":")).replace("T", " ")} label="Last Modified"></TextField> </Grid><Grid item xs={3} >
                                        <TextField disabled value={this.state.copyedit.modified_By} label="Modified By"></TextField></Grid>
                                    
                                    </Grid>
                                </Paper>
                         
                    <br></br>
                    {this.state.showError ? 
                        <Stack sx={{ width: '100%' }} spacing={2}>
                            <Alert variant="filled" severity="error" action={<IconButton color="inherit" onClick={() => {this.setState({showError:false})}}>X</IconButton>}>
                            {this.state.errorText}
                            </Alert><br></br>
                        </Stack>
                    :null}
                    <br></br>
                    <button onClick={this.cancelEdit.bind(this)} id="cancel-button">Close</button>
                    {this.state.showPopup? <Popup headerText="Leaving Page" text='Cancel edits and exit?' savePopup = {this.handlePopupExit.bind(this)} closePopup={this.handlePopupCancel.bind(this)}/>:null}
                    {/* removed  disabled={this.state.isOrderStatusCompleted} */}
                    <button onClick ={this.saveEdit.bind(this)} id="save-button">Save</button>
                    <Button variant='outlined' className="cancel-order-button" onClick={() => {this.setState({showCancelOrderPopup:true});}}>Cancel Order</Button>
                    {this.state.showCancelOrderPopup ? <Popup headerText="Cancelling order" text='Are you sure you want to cancel this order?' 
                        savePopup = {() => {this.setState({showCancelOrderPopup:false});
                            if(currentitem!=null){
                                currentitem.cancelled = "Yes";
                                this.cancelOrderConfirm();
                            }
                            else{
                                alert("Please indicate in the comments why this order was cancelled");
                            }
                            }} 
                        closePopup={() => {this.setState({showCancelOrderPopup:false})}} />
                    :null} 
                    </div>
                    </form> 
            </Paper>
            </Paper>
        );
        }
        else {
            return (null
            );
        }
    }
}
export default EditForm;
export {idx, currentitem};
export {savedEditView};