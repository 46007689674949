import '../../../shared/simos/styling/App.css';
import React, {Component} from 'react';
import CreateForm from './OrderPage/createForm';
import DisplayGrid from './OrderPage/gridView';
import WarehouseGrid from './WarehousePage/warehouseGridview';
import {Redirect, RouteComponentProps, Link, useHistory} from 'react-router-dom';
import NavigationMenu from '../../../shared/simos/helper/NavigationMenu';
import CompletedWarehouseGrid from './WarehousePage/completedView';
import {getSIMOSUserGroupAccess} from './APICalls/getSIMOSGroupAccess';
import urlContainsSILParameter, { getSILURLParams, redirectSIMOSHome } from '../../../shared/simos/helper/getURLContainsSIL';

interface IProps extends RouteComponentProps{
    history:any;
}

interface IState {
    showCreateForm:boolean;
    showGrid:boolean;
    switchView:string;
    headerText:string;
}

class SIMOSOrdersPage extends Component<IProps, IState>{
     constructor(props: IProps) {
        super(props);
        this.state = {
            showCreateForm: false,showGrid:true, switchView:'active', headerText:'Active Items'
        }
        this.createNewForm = this.createNewForm.bind(this);
        this.showGrid = this.showGrid.bind(this);
        this.closePopup = this.closePopup.bind(this);
     //   this.handleWarehouseRoute = this.handleWarehouseRoute.bind(this);
        this.handleComplete = this.handleComplete.bind(this);
     }
    
    createNewForm () { 
       this.props.history.push('/simos/orders/createForm'+ getSILURLParams());
        this.setState({showCreateForm: true});
       
    }

    closePopup() { 
        console.log('cancelled');
        this.props.history.push('/');

        this.setState({
            showCreateForm: !this.state.showCreateForm
        });
       
    }
    showGrid() {
        this.setState({showGrid:true});
        window.scrollTo({behavior:'smooth',top:0});
    }
    
    handleRoute(eventKey:any) {
       switch(eventKey) {
           case 'Home':
            this.props.history.push('/simos/orders'+ getSILURLParams());
            break;
        case 'CustomerContact':
            this.props.history.push('/simos/viewCustomerContacts'+ getSILURLParams());
            break;
        case 'Customers':
            this.props.history.push('/simos/customers'+ getSILURLParams());
            break;
        case 'TransportCompany':
            this.props.history.push('/simos/transport-companies'+ getSILURLParams());
            break;
        case 'Products':
            this.props.history.push('/simos/products'+ getSILURLParams());
            break;
       }
    }
    handleLogout(){
        //Auth.logout();
        this.props.history.replace('/simos');
    }
    handleComplete(){
        this.setState({switchView:'active',headerText:''});
    }

    async componentDidMount() {
        var response:any = await getSIMOSUserGroupAccess();
        var userRole:any = response.role;
        if(userRole!='Admin' && userRole!='Dispatcher' && userRole!='SuperAdmin'){
            this.props.history.push('/simos');
        }
        if(!urlContainsSILParameter()){
            redirectSIMOSHome();
        }
    }

    render (){
           // this.props.history.push('/simos/orders/editproduct');
        
            return (
                <div ><header className={getSILURLParams()?.includes("bru")?"header-fixed":"header-fixed-sil-ed"}> 
                <NavigationMenu handleRoute={this.handleRoute.bind(this)}></NavigationMenu>
                        <div className="header-limiter"> 
                        <h2>SIMOS {getSILURLParams()?.includes("bru")? <span>SIL Bru Orders</span>:<span>SIL Ed Orders</span>}    
                            <button type="submit" id={getSILURLParams()?.includes("bru")?"create-form-btn":"create-form-btn-sil-ed"} onClick={this.createNewForm}>Create New Form</button></h2></div>{this.state.showCreateForm? 
                    <CreateForm history={this.props.history} cancelPopup={this.closePopup.bind(this)}/>:null}</header>
                    
                    
                    {this.state.showGrid ? <div className={getSILURLParams()?.includes("bru")?"data-grid-fixed":"data-grid-fixed-sil-ed"}>
                    <DisplayGrid history ={this.props.history}showGrid={this.showGrid}></DisplayGrid>
                    </div>:null}
                
                
                </div>
            );
    }
}
export default SIMOSOrdersPage;
export type {IProps};