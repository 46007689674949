import React from 'react';
import '../../../../shared/simos/styling/createFormPage.css';
import NavigationMenu from '../../../../shared/simos/helper/NavigationMenu';
import {IProps} from '../SIMOSOrderPage';
import CustomerContactTable from './CustomerContactTable';
import CreateContactForm from './createContactForm';
import {getSIMOSUserGroupAccess} from '../APICalls/getSIMOSGroupAccess';
import urlContainsSILParameter, { getSILURLParams, redirectSIMOSHome } from '../../../../shared/simos/helper/getURLContainsSIL';

interface IState {
    showCreateCustomerForm:boolean;
}
class CustomerPage extends React.Component<IProps, IState> {
    constructor(props:IProps) {
        super(props);
        this.state = {
            showCreateCustomerForm: false
        }
    }

    handleRoute (eventKey:any){
        switch(eventKey) {
            case 'Home':
             this.props.history.push('/simos/orders' + getSILURLParams());
             break;
         case 'CustomerContact':
             this.props.history.push('/simos/viewCustomerContacts' + getSILURLParams());
             break;
         case 'TransportCompany':
              this.props.history.push('/simos/transport-companies' + getSILURLParams());
             break;
        case 'Customers':
            this.props.history.push('/simos/customers' + getSILURLParams());
            break;
        case 'Products':
            this.props.history.push('/simos/products' + getSILURLParams());
            break;
        }
    }
    createNewForm() {
        this.props.history.push('/simos/createCustomerContacts'+getSILURLParams());
        this.setState({showCreateCustomerForm:true});
    }

    handleLogout(){
        //Auth.logout();
        this.props.history.replace('/simos');
    }
    async componentWillMount(){
        var response:any = await getSIMOSUserGroupAccess();
        var userRole:any = response.role;
        if(userRole!='Admin' && userRole!='Dispatcher' && userRole!='SuperAdmin'){
            this.props.history.push('/simos');
        }
        if(!urlContainsSILParameter()){
            redirectSIMOSHome();
        }
    }

    render() {
        return <div>
            <header className={getSILURLParams()?.includes("bru")?"header-fixed":"header-fixed-sil-ed"}> 
                <NavigationMenu handleRoute={this.handleRoute.bind(this)}></NavigationMenu>
                <div className="header-limiter"> 
                    <h2>SIMOS<span> Customer Contacts</span><button type='submit' id={getSILURLParams()?.includes("bru")?"create-form-btn":"create-form-btn-sil-ed"} onClick={this.createNewForm.bind(this)}>Create New</button>
                    </h2>
                </div>
                {this.state.showCreateCustomerForm? <CreateContactForm values={null} closeCreateForm={null} isCreateFromOrderPage={false} history={this.props.history}></CreateContactForm>:null}
            </header>
            <div className={getSILURLParams()?.includes("bru")?"data-grid-fixed":"data-grid-fixed-sil-ed"}>
                <CustomerContactTable history={this.props.history}></CustomerContactTable>
            </div>
        </div>
    }
}

export default CustomerPage;