import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import { IDispatch, IStore } from "../redux/reducers";
import { DARK_RED_COLOR, MEDIA_QUERY_PHONE } from "./shared/theme";
import { LAPaperWithPadding } from "./shared/paper";
import styled from "styled-components";
import LAGrid from "./shared/grid";
import LAGridItem from "./shared/gridList";
import { ROUTE } from "./routes";
import PageSpacing from "./shared/pageSpacing";


interface IHomeComponentStoreProps {

};

interface IHomeComponentDispatchProps {

}

const HomeStyles = styled(LAPaperWithPadding)`
    margin: 30px 30px;
    word-break: break-word;

    .title {
        color: ${DARK_RED_COLOR};
        font-size: 40px;
        font-weight: bold;
        text-shadow: 2px 1px #b30003;
    };

    .appTitle {
        font-size: 30px;
        font-weight: bold;
        margin-top: 20%;
        text-shadow: 2px 1px #b30003;
    };

    .silTitle {
        color: #a9e7ff;
    };

    .simosTitle {
        color: #8289dd;
    };

    .yellowTitle {
        color: #ced138;
    };

    .catapultTitle {
        color: #ffffff;
    };

    .sil {
        background-image: url(/img/sil-tile.jpg);
    };

    .yellowhead {
        background-image: url(/img/yellow-tile.png);
    };

    .simos {
        background-image: url(/img/simos-tile.jpg);
    };
    .sil_ps {
        background-image: url(/img/sil_ps_tile.jpg);
    };

    .catapult {
        background-image: url(/img/catapult-tile.jpg);
    };

    .homeMenu {
        color: #000;
        height: 240px;
        cursor: pointer;
        border-radius: 15px;
        border: 4px solid #fff;
        background-color: #E7F2F8;
        box-shadow: 0 2px 10px 0 #b30003;
        background-repeat: no-repeat;
        background-size: cover;
        opacity: 5.0;
        -webkit-transition: background 2.5s linear;
        -moz-transition: background 2.5s linear;
        -o-transition: background 2.5s linear;
        -ms-transition: background 2.5s linear;
        transition: background 2.5s linear;
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 10px 10px;
    };
`;


type IHomeComponentProps = RouteComponentProps & IHomeComponentStoreProps & IHomeComponentDispatchProps;

class HomeComponent extends PureComponent<IHomeComponentProps> {

    public componentDidMount(): void {

    }

    public render(): ReactNode {

        return (
            <PageSpacing title="Propsense Home" description="Propsense Home" fixedSpaceOnSmallerScreens={true}>
                <HomeStyles>
                    <LAGrid spacing={2} className="text-center">

                        <LAGridItem xs={12}>
                            <h2 className="title">Propsense Portal</h2>
                        </LAGridItem>

                        <LAGridItem xs={2}>
                        </LAGridItem>

                        <LAGridItem xs={4}>
                            <div className="homeMenu simos" onClick={this.onSilPS}>
                                <h2 className="appTitle catapultTitle">SIL - Dispatch/Orders/Loads</h2>
                            </div>
                        </LAGridItem>

                        <LAGridItem xs={4}>
                            <div className="homeMenu simos" onClick={this.onSIMOS}>
                                <h2 className="appTitle catapultTitle">SIMOS</h2>
                            </div>
                        </LAGridItem>

                        <LAGridItem xs={2}>
                        </LAGridItem>

                        <LAGridItem xs={2}>
                        </LAGridItem>

                        <LAGridItem xs={4}>
                            <div className="homeMenu sil" onClick={this.onSIL}>
                                <h2 className="appTitle yellowTitle">SIL - Production/Carriers</h2>
                            </div>
                        </LAGridItem>

                        <LAGridItem xs={4}>
                            <div className="homeMenu yellowhead" onClick={this.onYellowHead}>
                                <h2 className="appTitle yellowTitle">Yellowhead Aggregates (YHD)</h2>
                            </div>
                        </LAGridItem>
                        <LAGridItem xs={2}>
                        </LAGridItem>

                        <LAGridItem xs={2}>
                        </LAGridItem>

                    </LAGrid>
                </HomeStyles>
            </PageSpacing>
        );
    }

    private onSilPS = (): void => {
        window.open(`https://lms.propsense.com/`, "_blank");
    };

    private onSIMOS = (): void => {
        this.props.history.push(ROUTE.SIMOS.INDEX);
    };

    private onYellowHead = (): void => {
        this.props.history.push(ROUTE.PROPSENSE.YHD.INDEX);
    };

    private onSIL = (): void => {
        this.props.history.push(ROUTE.SIL.INDEX);
    };

}

const mapDispatchToProps = (dispatch: IDispatch): IHomeComponentDispatchProps => ({

});

const mapStateToProps = (state: IStore): IHomeComponentStoreProps => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(HomeComponent);