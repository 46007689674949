import { getCarrierLookupsEpic } from './sil/carrier/getCarrierLookups/getCarrierLookupsEpic';
import { combineEpics, Epic } from "redux-observable";
import { getAssignedCrewsEpic } from "./propsense/catapult/crew_sheduling/getAssignedCrews/getAssignedCrewsEpic";
import { getCrewsEpic } from "./propsense/catapult/crew_sheduling/getCrews/getCrewsEpic";
import { getJobsEpic } from "./propsense/catapult/crew_sheduling/getJobs/getJobsEpic";
import { saveAssignedCrewsEpic } from "./propsense/catapult/crew_sheduling/saveAssignedCrews/saveAssignedCrewsEpic";
import { IAction, IStore } from "./reducers";
import { EpicDependencies } from "./store";
import { addCarrierEmailEpic } from "./sil/carrier/addCarrierEmail/addCarrierEmailEpic";
import { addCarrierFleetTrailerEpic } from "./sil/carrier/addCarrierFleetTrailer/addCarrierFleetTrailerEpic";
import { getCarrierEmailsEpic } from "./sil/carrier/getCarrierEmails/getCarrierEmailsEpic";
import { getCarrierFleetTrailersEpic } from "./sil/carrier/getCarrierFleetTrailers/getCarrierFleetTrailersEpic";
import { getCarriersEpic } from "./sil/carrier/getCarriers/getCarriersEpic";
import { saveCarrierFormEpic } from "./sil/carrier/saveCarrierForm/saveCarrierFormEpic";
import { updateCarrierEmailEpic } from "./sil/carrier/updateCarrierEmail/updateCarrierEmailEpic";
import { updateCarrierFleetTrailerEpic } from "./sil/carrier/updateCarrierFleetTrailer/updateCarrierFleetTrailerEpic";
import { updateCarrierFormEpic } from "./sil/carrier/updateCarrierForm/updateCarrierFormEpic";
import { addBinAdjustmentEpic } from "./sil/dryPlant/addBinAdjustment/addBinAdjustmentEpic";
import { addEmployeeEpic } from "./sil/dryPlant/addEmployee/addEmployeeEpic";
import { addFeedMaterialEpic } from "./sil/dryPlant/addFeedMaterial/addFeedMaterialEpic";
import { addProductionEpic } from "./sil/dryPlant/addProduction/addProductionEpic";
import { addRunMaterialEpic } from "./sil/dryPlant/addRunMaterial/addRunMaterialEpic";
import { getBinAdjustmentsEpic } from "./sil/dryPlant/getBinAdjustments/getBinAdjustmentsEpic";
import { getEmployeesEpic } from "./sil/dryPlant/getEmployees/getEmployeesEpic";
import { getFeedMaterialsEpic } from "./sil/dryPlant/getFeedMaterials/getFeedMaterialsEpic";
import { getProductionLookupsEpic } from "./sil/dryPlant/getProductionLookups/getProductionLookupsEpic";
import { getProductionRecordsEpic } from "./sil/dryPlant/getProductionRecords/getProductionRecordsEpic";
import { getProductLookupsEpic } from "./sil/dryPlant/getProductLookups/getProductLookupsEpic";
import { getRunMaterialsEpic } from "./sil/dryPlant/getRunMaterials/getRunMaterialsEpic";
import { getTokenEpic } from "./sil/dryPlant/token/getTokenEpic";
import { updateBinAdjustmentEpic } from "./sil/dryPlant/updateBinAdjustment/updateBinAdjustmentEpic";
import { updateEmployeeEpic } from "./sil/dryPlant/updateEmployee/updateEmployeeEpic";
import { updateFeedMaterialEpic } from "./sil/dryPlant/updateFeedMaterial/updateFeedMaterialEpic";
import { updateProductionEpic } from "./sil/dryPlant/updateProduction/updateProductionEpic";
import { updateRunMaterialEpic } from "./sil/dryPlant/updateRunMaterial/updateRunMaterialEpic";
import { getProductionSummaryEpic } from './sil/dryPlant/getProductionSummary/getProductionSummaryEpic';
import { updateWashPlantRunMaterialEpic } from './sil/washPlant/updateWashPlantRunMaterial/updateWashPlantRunMaterialEpic';
import { addWashPlantBinAdjustmentEpic } from './sil/washPlant/addWashPlantBinAdjustment/addWashPlantBinAdjustmentEpic';
import { addWashPlantFeedMaterialEpic } from './sil/washPlant/addWashPlantFeedMaterial/addWashPlantFeedMaterialEpic';
import { addWashPlantProductionEpic } from './sil/washPlant/addWashPlantProduction/addWashPlantProductionEpic';
import { addWashPlantRunMaterialEpic } from './sil/washPlant/addWashPlantRunMaterial/addWashPlantRunMaterialEpic';
import { getWashPlantBinAdjustmentsEpic } from './sil/washPlant/getWashPlantBinAdjustments/getWashPlantBinAdjustmentsEpic';
import { getWashPlantFeedMaterialsEpic } from './sil/washPlant/getWashPlantFeedMaterials/getWashPlantFeedMaterialsEpic';
import { getWashPlantProductionLookupsEpic } from './sil/washPlant/getWashPlantProductionLookups/getWashPlantProductionLookupsEpic';
import { getWashPlantProductionRecordsEpic } from './sil/washPlant/getWashPlantProductionRecords/getWashPlantProductionRecordsEpic';
import { getWashPlantProductionSummaryEpic } from './sil/washPlant/getWashPlantProductionSummary/getWashPlantProductionSummaryEpic';
import { getWashPlantProductLookupsEpic } from './sil/washPlant/getWashPlantProductLookups/getWashPlantProductLookupsEpic';
import { getWashPlantRunMaterialsEpic } from './sil/washPlant/getWashPlantRunMaterials/getWashPlantRunMaterialsEpic';
import { updateWashPlantBinAdjustmentEpic } from './sil/washPlant/updateWashPlantBinAdjustment/updateWashPlantBinAdjustmentEpic';
import { updateWashPlantFeedMaterialEpic } from './sil/washPlant/updateWashPlantFeedMaterial/updateWashPlantFeedMaterialEpic';
import { updateWashPlantProductionEpic } from './sil/washPlant/updateWashPlantProduction/updateWashPlantProductionEpic';
import { addYHDCarrierEmailEpic } from './propsense/yhd/carrier/addCarrierEmail/addCarrierEmailEpic';
import { getYHDCarrierEmailsEpic } from './propsense/yhd/carrier/getCarrierEmails/getCarrierEmailsEpic';
import { getYHDCarrierLookupsEpic } from './propsense/yhd/carrier/getCarrierLookups/getCarrierLookupsEpic';
import { getYHDCarriersEpic } from './propsense/yhd/carrier/getCarriers/getCarriersEpic';
import { saveYHDCarrierFormEpic } from './propsense/yhd/carrier/saveCarrierForm/saveCarrierFormEpic';
import { updateYHDCarrierEmailEpic } from './propsense/yhd/carrier/updateCarrierEmail/updateCarrierEmailEpic';
import { updateYHDCarrierFormEpic } from './propsense/yhd/carrier/updateCarrierForm/updateCarrierFormEpic';
import { getYHDCarrierFleetTruckEpic } from './propsense/yhd/carrier/getCarrierFleetTruck/getCarrierFleetTruckEpic';
import { addYHDCarrierFleetTruckEpic } from './propsense/yhd/carrier/addCarrierFleetTruck/addCarrierFleetTruckEpic';
import { updateYHDCarrierFleetTruckEpic } from './propsense/yhd/carrier/updateCarrierFleetTruck/updateCarrierFleetTruckEpic';
import { getYHDCarrierInfractionsEpic } from './propsense/yhd/carrier/getCarrierInfractions/getCarrierInfractionsEpic';
import { addYHDCarrierInfractionEpic } from './propsense/yhd/carrier/addCarrierInfraction/addCarrierInfractionEpic';
import { updateYHDCarrierInfractionEpic } from './propsense/yhd/carrier/updateCarrierInfraction/updateCarrierInfractionEpic';
import { getSieveCalibrationsEpic } from './sil/sieves/getSievesCalibrations/getSievesCalibrationsEpic';
import { getSieveLookupsEpic } from './sil/sieves/getSieveLookups/getSieveLookupsEpic';
import { addSieveCalibrationEpic } from './sil/sieves/addSieveCalibration/addSieveCalibrationEpic';
import { updateSieveCalibrationEpic } from './sil/sieves/updateSieveCalibration/updateSieveCalibrationEpic';
import { getSieveChecksEpic } from './sil/sieves/getSievesChecks/getSievesChecksEpic';
import { addSieveCheckEpic } from './sil/sieves/addSieveCheck/addSieveCheckEpic';
import { updateSieveCheckEpic } from './sil/sieves/updateSieveCheck/updateSieveCheckEpic';
import { getSievesEpic } from './sil/sieves/getSieves/getSievesChecksEpic';
import { updateSieveEpic } from './sil/sieves/updateSieve/updateSieveEpic';
import { addSieveEpic } from './sil/sieves/addSieve/addSieveEpic';
import { getCarrierInfractionsEpic } from './sil/carrier/getCarrierInfractions/getCarrierInfractionsEpic';
import { addCarrierInfractionEpic } from './sil/carrier/addCarrierInfraction/addCarrierInfractionEpic';
import { updateCarrierInfractionEpic } from './sil/carrier/updateCarrierInfraction/updateCarrierInfractionEpic';
import { getSieveProductsEpic } from './sil/sieves/getSievesProducts/getSievesProductsEpic';
import { addSieveProductEpic } from './sil/sieves/addSieveProduct/addSieveProductEpic';
import { updateSieveProductEpic } from './sil/sieves/updateSieveProduct/updateSieveProductEpic';
import { getChemicalAnalysesEpic } from './sil/exploration/chemicalAnalyses/getChemicalAnalyses/getChemicalAnalysesEpic';
import { addChemicalAnalysesEpic } from './sil/exploration/chemicalAnalyses/addChemicalAnalyses/addChemicalAnalysesEpic';
import { updateChemicalAnalysesEpic } from './sil/exploration/chemicalAnalyses/updateChemicalAnalyses/updateChemicalAnalysesEpic';
import { getWarehouseProductsEpic } from './sil/warehouse/warehouseProducts/getWarehouseProducts/getWarehouseProductsEpic';
import { addWarehouseProductEpic } from './sil/warehouse/warehouseProducts/addWarehouseProduct/addWarehouseProductEpic';
import { updateWarehouseProductEpic } from './sil/warehouse/warehouseProducts/updateWarehouseProduct/updateWarehouseProductEpic';
import { getBaggedInventoriesEpic } from './sil/warehouse/baggedInventory/getBaggedInventories/getBaggedInventoriesEpic';
import { addBaggedInventoryEpic } from './sil/warehouse/baggedInventory/addBaggedInventory/addBaggedInventoryEpic';
import { updateBaggedInventoryEpic } from './sil/warehouse/baggedInventory/updateBaggedInventory/updateBaggedInventoryEpic';
import { getPSLookupsEpic } from './sil/warehouse/baggedInventory/getSieveLookups/getPSLookupsEpic';
import { getComplaintLoggingEpic } from './sil/complaintLogging/getComplaintLogging/getComplaintLoggingEpic';
import { updateComplaintLoggingEpic } from './sil/complaintLogging/updateComplaintLogging/updateComplaintLoggingEpic';
import { addComplaintLoggingEpic } from './sil/complaintLogging/addComplaintLogging/addComplaintLoggingEpic';

import { getBeltScaleEpic } from './sil/cv/beltScales/getBeltScales/getBeltScalesEpic';
import { addBeltScaleEpic } from './sil/cv/beltScales/addBeltScale/addBeltScaleEpic';
import { updateBeltScaleEpic } from './sil/cv/beltScales/updateBeltScale/updateBeltScaleEpic';
import { addCraneEpic } from './sil/cv/cranes/addCrane/addCraneEpic';
import { getCraneEpic } from './sil/cv/cranes/getCranes/getCranesEpic';
import { updateCraneEpic } from './sil/cv/cranes/updateCrane/updateCraneEpic';
import { addLabScaleEpic } from './sil/cv/labScales/addLabScale/addLabScaleEpic';
import { getLabScaleEpic } from './sil/cv/labScales/getLabScales/getLabScalesEpic';
import { updateLabScaleEpic } from './sil/cv/labScales/updateLabScale/updateLabScaleEpic';
import { addNCREpic } from './sil/cv/ncrs/addNCR/addNCREpic';
import { getNCREpic } from './sil/cv/ncrs/getNCRs/getNCRsEpic';
import { updateNCREpic } from './sil/cv/ncrs/updateNCR/updateNCREpic';
import { addSieveDryPlantEpic } from './sil/cv/sieveDryPlant/addSieveDryPlant/addSieveDryPlantEpic';
import { getSieveDryPlantEpic } from './sil/cv/sieveDryPlant/getSieveDryPlants/getSieveDryPlantsEpic';
import { updateSieveDryPlantEpic } from './sil/cv/sieveDryPlant/updateSieveDryPlant/updateSieveDryPlantEpic';
import { addSieveFracBallEpic } from './sil/cv/sieveFracBall/addSieveFracBall/addSieveFracBallEpic';
import { getSieveFracBallEpic } from './sil/cv/sieveFracBall/getSieveFracBalls/getSieveFracBallsEpic';
import { updateSieveFracBallEpic } from './sil/cv/sieveFracBall/updateSieveFracBall/updateSieveFracBallEpic';
import { addSieveWashPlantEpic } from './sil/cv/sieveWashPlant/addSieveWashPlant/addSieveWashPlantEpic';
import { getSieveWashPlantEpic } from './sil/cv/sieveWashPlant/getSieveWashPlants/getSieveWashPlantsEpic';
import { updateSieveWashPlantEpic } from './sil/cv/sieveWashPlant/updateSieveWashPlant/updateSieveWashPlantEpic';
import { addTruckScaleEpic } from './sil/cv/truckScales/addTruckScale/addTruckScaleEpic';
import { getTruckScaleEpic } from './sil/cv/truckScales/getTruckScales/getTruckScalesEpic';
import { updateTruckScaleEpic } from './sil/cv/truckScales/updateTruckScale/updateTruckScaleEpic';
import { addWarehouseEpic } from './sil/cv/warehouses/addWarehouse/addWarehouseEpic';
import { getWarehouseEpic } from './sil/cv/warehouses/getWarehouses/getWarehousesEpic';
import { updateWarehouseEpic } from './sil/cv/warehouses/updateWarehouse/updateWarehouseEpic';

import { getQualityControlTestingsEpic } from './sil/qualityControlTestings/getQualityControlTestings/getQualityControlTestingsEpic';
import { addQualityControlTestingEpic } from './sil/qualityControlTestings/addQualityControlTesting/addQualityControlTestingEpic';
import { updateQualityControlTestingEpic } from './sil/qualityControlTestings/updateQualityControlTesting/updateQualityControlTestingEpic';
import { getWashPlantProductionRecordByIdEpic } from './sil/washPlant/getWashPlantProductionRecordById/getWashPlantProductionRecordByIdEpic';
import { getGeoBagsEpic } from './sil/dispatch/getGeoBags/getGeoBagsEpic';
import { getTrailerActivityLogsEpic } from './sil/dispatch/getTrailerActivityLogs/getTrailerActivityLogsEpic';
import { getTransloadBOLCountsEpic } from './sil/dispatch/getTransloadBOLCounts/getTransloadBOLCountsEpic';
import { updateGeoBagEpic } from './sil/dispatch/updateGeoBag/updateGeoBagEpic';
import { addTrailerActivityLogEpic } from './sil/dispatch/addTrailerActivityLog/addTrailerActivityLogEpic';
import { updateTrailerActivityLogEpic } from './sil/dispatch/updateTrailerActivityLog/updateTrailerActivityLogEpic';
import { addTransloadBOLCountEpic } from './sil/dispatch/addTransloadBOLCount/addTransloadBOLCountEpic';
import { updateTransloadBOLCountEpic } from './sil/dispatch/updateTransloadBOLCount/updateTransloadBOLCountEpic';
import { addGeoBagEpic } from './sil/dispatch/addGeoBag/addGeoBagEpic';
import { getCarrierAuditEpic } from './sil/carrier/audit/getAudit/getCarrierAuditEpic';
import { updateCarrierAuditEpic } from './sil/carrier/audit/updateAudit/updateAuditEpic';


export type IEpic = Epic<IAction, IAction, IStore, EpicDependencies>;

export default combineEpics(
    getCrewsEpic,
    getJobsEpic,
    saveCarrierFormEpic,
    getAssignedCrewsEpic,
    saveAssignedCrewsEpic,
    getEmployeesEpic,
    getFeedMaterialsEpic,
    getProductionLookupsEpic,
    getProductionRecordsEpic,
    getRunMaterialsEpic,
    getTokenEpic,
    updateEmployeeEpic,
    updateFeedMaterialEpic,
    updateProductionEpic,
    updateRunMaterialEpic,
    addRunMaterialEpic,
    addProductionEpic,
    addFeedMaterialEpic,
    addEmployeeEpic,
    getCarriersEpic,
    updateCarrierFormEpic,
    getProductLookupsEpic,
    getBinAdjustmentsEpic,
    addBinAdjustmentEpic,
    updateBinAdjustmentEpic,
    getCarrierEmailsEpic,
    addCarrierEmailEpic,
    updateCarrierEmailEpic,
    getCarrierFleetTrailersEpic,
    addCarrierFleetTrailerEpic,
    updateCarrierFleetTrailerEpic,
    getCarrierLookupsEpic,
    getProductionSummaryEpic,
    updateWashPlantRunMaterialEpic,
    updateWashPlantProductionEpic,
    updateWashPlantFeedMaterialEpic,
    updateWashPlantBinAdjustmentEpic,
    getWashPlantRunMaterialsEpic,
    getWashPlantProductLookupsEpic,
    getWashPlantProductionSummaryEpic,
    getWashPlantProductionRecordsEpic,
    getWashPlantProductionLookupsEpic,
    getWashPlantFeedMaterialsEpic,
    getWashPlantBinAdjustmentsEpic,
    addWashPlantRunMaterialEpic,
    addWashPlantFeedMaterialEpic,
    addWashPlantBinAdjustmentEpic,
    addWashPlantProductionEpic,
    addYHDCarrierEmailEpic,
    getYHDCarrierEmailsEpic,
    getYHDCarrierLookupsEpic,
    getYHDCarriersEpic,
    saveYHDCarrierFormEpic,
    updateYHDCarrierEmailEpic,
    updateYHDCarrierFormEpic,
    getYHDCarrierFleetTruckEpic,
    addYHDCarrierFleetTruckEpic,
    updateYHDCarrierFleetTruckEpic,
    getYHDCarrierInfractionsEpic,
    addYHDCarrierInfractionEpic,
    updateYHDCarrierInfractionEpic,
    getSieveCalibrationsEpic,
    getSieveLookupsEpic,
    addSieveCalibrationEpic,
    updateSieveCalibrationEpic,
    getSieveChecksEpic,
    addSieveCheckEpic,
    updateSieveCheckEpic,
    getSievesEpic,
    updateSieveEpic,
    addSieveEpic,
    getCarrierInfractionsEpic,
    addCarrierInfractionEpic,
    updateCarrierInfractionEpic,
    getSieveProductsEpic,
    addSieveProductEpic,
    updateSieveProductEpic,
    getChemicalAnalysesEpic,
    addChemicalAnalysesEpic,
    updateChemicalAnalysesEpic,
    getWarehouseProductsEpic,
    addWarehouseProductEpic,
    updateWarehouseProductEpic,
    getBaggedInventoriesEpic,
    addBaggedInventoryEpic,
    updateBaggedInventoryEpic,
    getPSLookupsEpic,
    getComplaintLoggingEpic,
    updateComplaintLoggingEpic,
    addComplaintLoggingEpic,
    getBeltScaleEpic,
    addBeltScaleEpic,
    updateBeltScaleEpic,
    addCraneEpic,
    getCraneEpic,
    updateCraneEpic,
    addLabScaleEpic,
    getLabScaleEpic,
    updateLabScaleEpic,
    addNCREpic,
    getNCREpic,
    updateNCREpic,
    addSieveDryPlantEpic,
    getSieveDryPlantEpic,
    updateSieveDryPlantEpic,
    addSieveFracBallEpic,
    getSieveFracBallEpic,
    updateSieveFracBallEpic,
    addSieveWashPlantEpic,
    getSieveWashPlantEpic,
    updateSieveWashPlantEpic,
    addTruckScaleEpic,
    getTruckScaleEpic,
    updateTruckScaleEpic,
    addWarehouseEpic,
    getWarehouseEpic,
    updateWarehouseEpic,
    getQualityControlTestingsEpic,
    addQualityControlTestingEpic,
    updateQualityControlTestingEpic,
    getWashPlantProductionRecordByIdEpic,
    getGeoBagsEpic,
    getTrailerActivityLogsEpic,
    getTransloadBOLCountsEpic,
    addGeoBagEpic,
    updateGeoBagEpic,
    addTrailerActivityLogEpic,
    updateTrailerActivityLogEpic,
    addTransloadBOLCountEpic,
    updateTransloadBOLCountEpic,
    getCarrierAuditEpic,
    updateCarrierAuditEpic
);