import { Reducer, combineReducers, Dispatch } from "redux";
import { ById, ByIdNumber, PropsenseAPIResponse, SilAPIResponse, YHDAPIResponse } from "../react/shared/publicInterfaces";
import { IAssignedCrew } from "./propsense/catapult/crew_sheduling/getAssignedCrews/getAssignedCrewsConstants";
import { GetAssignedCrewsReducer } from "./propsense/catapult/crew_sheduling/getAssignedCrews/getAssignedCrewsReducer";
import { ICrew } from "./propsense/catapult/crew_sheduling/getCrews/getCrewsConstants";
import { GetCrewsReducer } from "./propsense/catapult/crew_sheduling/getCrews/getCrewsReducer";
import { IJob } from "./propsense/catapult/crew_sheduling/getJobs/getJobsConstants";
import { GetJobsReducer } from "./propsense/catapult/crew_sheduling/getJobs/getJobsReducer";
import { SaveAssignedCrewsReducer } from "./propsense/catapult/crew_sheduling/saveAssignedCrews/saveAssignedCrewsReducer";
import { AddYHDCarrierEmailReducer } from "./propsense/yhd/carrier/addCarrierEmail/addCarrierEmailReducer";
import { AddYHDCarrierFleetTruckReducer } from "./propsense/yhd/carrier/addCarrierFleetTruck/addCarrierFleetTruckReducer";
import { AddYHDCarrierInfractionReducer } from "./propsense/yhd/carrier/addCarrierInfraction/addCarrierInfractionReducer";
import { IYHDCarrierEmail } from "./propsense/yhd/carrier/getCarrierEmails/getCarrierEmailsConstaints";
import { GetYHDCarrierEmailsReducer } from "./propsense/yhd/carrier/getCarrierEmails/getCarrierEmailsReducer";
import { IYHDCarrierFleetTruck } from "./propsense/yhd/carrier/getCarrierFleetTruck/getCarrierFleetTruckConstaints";
import { GetYHDCarrierFleetTruckReducer } from "./propsense/yhd/carrier/getCarrierFleetTruck/getCarrierFleetTruckReducer";
import { IYHDCarrierInfractions } from "./propsense/yhd/carrier/getCarrierInfractions/getCarrierInfractionsConstaints";
import { GetYHDCarrierInfractionsReducer } from "./propsense/yhd/carrier/getCarrierInfractions/getCarrierInfractionsReducer";
import { IYHDCarrierLookup } from "./propsense/yhd/carrier/getCarrierLookups/getCarrierLookupsConstaints";
import { GetYHDCarrierLookupsReducer } from "./propsense/yhd/carrier/getCarrierLookups/getCarrierLookupsReducer";
import { IYHDCarrier } from "./propsense/yhd/carrier/getCarriers/getCarriersConstaints";
import { GetYHDCarriersReducer } from "./propsense/yhd/carrier/getCarriers/getCarriersReducer";
import { SaveYHDCarrierFormReducer } from "./propsense/yhd/carrier/saveCarrierForm/saveCarrierFormReducer";
import { UpdateYHDCarrierEmailReducer } from "./propsense/yhd/carrier/updateCarrierEmail/updateCarrierEmailReducer";
import { UpdateYHDCarrierFleetTruckReducer } from "./propsense/yhd/carrier/updateCarrierFleetTruck/updateCarrierFleetTruckReducer";
import { UpdateYHDCarrierFormReducer } from "./propsense/yhd/carrier/updateCarrierForm/updateCarrierFormReducer";
import { UpdateYHDCarrierInfractionReducer } from "./propsense/yhd/carrier/updateCarrierInfraction/updateCarrierInfractionReducer";
import { Server } from "./server";
import { AddCarrierEmailReducer } from "./sil/carrier/addCarrierEmail/addCarrierEmailReducer";
import { AddCarrierFleetTrailerReducer } from "./sil/carrier/addCarrierFleetTrailer/addCarrierFleetTrailerReducer";
import { AddCarrierInfractionReducer } from "./sil/carrier/addCarrierInfraction/addCarrierInfractionReducer";
import { ICarrierEmail } from "./sil/carrier/getCarrierEmails/getCarrierEmailsConstaints";
import { GetCarrierEmailsReducer } from "./sil/carrier/getCarrierEmails/getCarrierEmailsReducer";
import { ICarrierFleetTrailer } from "./sil/carrier/getCarrierFleetTrailers/getCarrierFleetTrailersConstaints";
import { GetCarrierFleetTrailersReducer } from "./sil/carrier/getCarrierFleetTrailers/getCarrierFleetTrailersReducer";
import { ICarrierInfraction } from "./sil/carrier/getCarrierInfractions/getCarrierInfractionsConstaints";
import { GetCarrierInfractionsReducer } from "./sil/carrier/getCarrierInfractions/getCarrierInfractionsReducer";
import { ICarrierLookup } from "./sil/carrier/getCarrierLookups/getCarrierLookupsConstaints";
import { GetCarrierLookupsReducer } from "./sil/carrier/getCarrierLookups/getCarrierLookupsReducer";
import { ICarrier } from "./sil/carrier/getCarriers/getCarriersConstaints";
import { GetCarriersReducer } from "./sil/carrier/getCarriers/getCarriersReducer";
import { SaveCarrierFormReducer } from "./sil/carrier/saveCarrierForm/saveCarrierFormReducer";
import { UpdateCarrierEmailReducer } from "./sil/carrier/updateCarrierEmail/updateCarrierEmailReducer";
import { UpdateCarrierFleetTrailerReducer } from "./sil/carrier/updateCarrierFleetTrailer/updateCarrierFleetTrailerReducer";
import { UpdateCarrierFormReducer } from "./sil/carrier/updateCarrierForm/updateCarrierFormReducer";
import { UpdateCarrierInfractionReducer } from "./sil/carrier/updateCarrierInfraction/updateCarrierInfractionReducer";
import { AddBinAdjustmentReducer } from "./sil/dryPlant/addBinAdjustment/addBinAdjustmentReducer";
import { AddEmployeeReducer } from "./sil/dryPlant/addEmployee/addEmployeeReducer";
import { AddFeedMaterialReducer } from "./sil/dryPlant/addFeedMaterial/addFeedMaterialReducer";
import { AddProductionReducer } from "./sil/dryPlant/addProduction/addProductionReducer";
import { AddRunMaterialReducer } from "./sil/dryPlant/addRunMaterial/addRunMaterialReducer";
import { IBinAdjustments } from "./sil/dryPlant/getBinAdjustments/getBinAdjustmentsConstaints";
import { GetBinAdjustmentsReducer } from "./sil/dryPlant/getBinAdjustments/getBinAdjustmentsReducer";
import { IEmployees } from "./sil/dryPlant/getEmployees/getEmployeesConstaints";
import { GetEmployeesReducer } from "./sil/dryPlant/getEmployees/getEmployeesReducer";
import { IFeedMaterials } from "./sil/dryPlant/getFeedMaterials/getFeedMaterialsConstaints";
import { GetFeedMaterialsReducer } from "./sil/dryPlant/getFeedMaterials/getFeedMaterialsReducer";
import { IProductionLookups } from "./sil/dryPlant/getProductionLookups/getProductionLookupsConstaints";
import { GetProductionLookupsReducer } from "./sil/dryPlant/getProductionLookups/getProductionLookupsReducer";
import { IProductionRecords } from "./sil/dryPlant/getProductionRecords/getProductionRecordsConstaints";
import { GetProductionRecordsReducer } from "./sil/dryPlant/getProductionRecords/getProductionRecordsReducer";
import { IProductionSummary } from "./sil/dryPlant/getProductionSummary/getProductionSummaryConstants";
import { GetProductionSummaryReducer } from "./sil/dryPlant/getProductionSummary/getProductionSummaryReducer";
import { GetProductLookupsReducer } from "./sil/dryPlant/getProductLookups/getProductLookupsReducer";
import { IRunMaterials } from "./sil/dryPlant/getRunMaterials/getRunMaterialsConstaints";
import { GetRunMaterialsReducer } from "./sil/dryPlant/getRunMaterials/getRunMaterialsReducer";
import { IToken } from "./sil/dryPlant/token/getTokenConstants";
import { GetTokenReducer } from "./sil/dryPlant/token/getTokenReducer";
import { UpdateBinAdjustmentReducer } from "./sil/dryPlant/updateBinAdjustment/updateBinAdjustmentReducer";
import { UpdateEmployeeReducer } from "./sil/dryPlant/updateEmployee/updateEmployeeReducer";
import { UpdateFeedMaterialReducer } from "./sil/dryPlant/updateFeedMaterial/updateFeedMaterialReducer";
import { UpdateProductionReducer } from "./sil/dryPlant/updateProduction/updateProductionReducer";
import { UpdateRunMaterialReducer } from "./sil/dryPlant/updateRunMaterial/updateRunMaterialReducer";
import { AddSieveReducer } from "./sil/sieves/addSieve/addSieveReducer";
import { AddSieveCalibrationReducer } from "./sil/sieves/addSieveCalibration/addSieveCalibrationReducer";
import { AddSieveCheckReducer } from "./sil/sieves/addSieveCheck/addSieveCheckReducer";
import { ISieveLookup } from "./sil/sieves/getSieveLookups/getSieveLookupsConstaints";
import { GetSieveLookupsReducer } from "./sil/sieves/getSieveLookups/getSieveLookupsReducer";
import { ISieve } from "./sil/sieves/getSieves/getSievesConstaints";
import { GetSievesReducer } from "./sil/sieves/getSieves/getSievesReducer";
import { ISieveCalibration } from "./sil/sieves/getSievesCalibrations/getSievesCalibrationsConstaints";
import { GetSieveCalibrationsReducer } from "./sil/sieves/getSievesCalibrations/getSievesCalibrationsReducer";
import { ISieveCheck } from "./sil/sieves/getSievesChecks/getSievesChecksConstaints";
import { GetSieveChecksReducer } from "./sil/sieves/getSievesChecks/getSievesChecksReducer";
import { UpdateSieveReducer } from "./sil/sieves/updateSieve/updateSieveReducer";
import { UpdateSieveCalibrationReducer } from "./sil/sieves/updateSieveCalibration/updateSieveCalibrationReducer";
import { UpdateSieveCheckReducer } from "./sil/sieves/updateSieveCheck/updateSieveCheckReducer";
import { AddWashPlantBinAdjustmentReducer } from "./sil/washPlant/addWashPlantBinAdjustment/addWashPlantBinAdjustmentReducer";
import { AddWashPlantFeedMaterialReducer } from "./sil/washPlant/addWashPlantFeedMaterial/addWashPlantFeedMaterialReducer";
import { AddWashPlantProductionReducer } from "./sil/washPlant/addWashPlantProduction/addWashPlantProductionReducer";
import { AddWashPlantRunMaterialReducer } from "./sil/washPlant/addWashPlantRunMaterial/addWashPlantRunMaterialReducer";
import { IWashPlantBinAdjustments } from "./sil/washPlant/getWashPlantBinAdjustments/getWashPlantBinAdjustmentsConstaints";
import { GetWashPlantBinAdjustmentsReducer } from "./sil/washPlant/getWashPlantBinAdjustments/getWashPlantBinAdjustmentsReducer";
import { IWashPlantFeedMaterials } from "./sil/washPlant/getWashPlantFeedMaterials/getWashPlantFeedMaterialsConstaints";
import { GetWashPlantFeedMaterialsReducer } from "./sil/washPlant/getWashPlantFeedMaterials/getWashPlantFeedMaterialsReducer";
import { IWashPlantProductionLookups } from "./sil/washPlant/getWashPlantProductionLookups/getWashPlantProductionLookupsConstaints";
import { GetWashPlantProductionLookupsReducer } from "./sil/washPlant/getWashPlantProductionLookups/getWashPlantProductionLookupsReducer";
import { IWashPlantProductionRecords } from "./sil/washPlant/getWashPlantProductionRecords/getWashPlantProductionRecordsConstaints";
import { GetWashPlantProductionRecordsReducer } from "./sil/washPlant/getWashPlantProductionRecords/getWashPlantProductionRecordsReducer";
import { IWashPlantProductionSummary } from "./sil/washPlant/getWashPlantProductionSummary/getWashPlantProductionSummaryConstants";
import { GetWashPlantProductionSummaryReducer } from "./sil/washPlant/getWashPlantProductionSummary/getWashPlantProductionSummaryReducer";
import { GetWashPlantProductLookupsReducer } from "./sil/washPlant/getWashPlantProductLookups/getWashPlantProductLookupsReducer";
import { IWashPlantRunMaterials } from "./sil/washPlant/getWashPlantRunMaterials/getWashPlantRunMaterialsConstaints";
import { GetWashPlantRunMaterialsReducer } from "./sil/washPlant/getWashPlantRunMaterials/getWashPlantRunMaterialsReducer";
import { UpdateWashPlantBinAdjustmentReducer } from "./sil/washPlant/updateWashPlantBinAdjustment/updateWashPlantBinAdjustmentReducer";
import { UpdateWashPlantFeedMaterialReducer } from "./sil/washPlant/updateWashPlantFeedMaterial/updateWashPlantFeedMaterialReducer";
import { UpdateWashPlantProductionReducer } from "./sil/washPlant/updateWashPlantProduction/updateWashPlantProductionReducer";
import { UpdateWashPlantRunMaterialReducer } from "./sil/washPlant/updateWashPlantRunMaterial/updateWashPlantRunMaterialReducer";
import { GetSieveProductsReducer } from "./sil/sieves/getSievesProducts/getSievesProductsReducer";
import { ISieveProduct } from "./sil/sieves/getSievesProducts/getSievesProductsConstaints";
import { AddSieveProductReducer } from "./sil/sieves/addSieveProduct/addSieveProductReducer";
import { UpdateSieveProductReducer } from "./sil/sieves/updateSieveProduct/updateSieveProductReducer";
import { GetChemicalAnalysesReducer } from "./sil/exploration/chemicalAnalyses/getChemicalAnalyses/getChemicalAnalysesReducer";
import { IChemicalAnalyses } from "./sil/exploration/chemicalAnalyses/getChemicalAnalyses/getChemicalAnalysesConstaints";
import { AddChemicalAnalysesReducer } from "./sil/exploration/chemicalAnalyses/addChemicalAnalyses/addChemicalAnalysesReducer";
import { UpdateChemicalAnalysesReducer } from "./sil/exploration/chemicalAnalyses/updateChemicalAnalyses/updateChemicalAnalysesReducer";
import { IWarehouseProducts } from "./sil/warehouse/warehouseProducts/getWarehouseProducts/getWarehouseProductsConstaints";
import { GetWarehouseProductsReducer } from "./sil/warehouse/warehouseProducts/getWarehouseProducts/getWarehouseProductsReducer";
import { AddWarehouseProductReducer } from "./sil/warehouse/warehouseProducts/addWarehouseProduct/addWarehouseProductReducer";
import { UpdateWarehouseProductReducer } from "./sil/warehouse/warehouseProducts/updateWarehouseProduct/updateWarehouseProductReducer";
import { IBaggedInventories } from "./sil/warehouse/baggedInventory/getBaggedInventories/getBaggedInventoriesConstaints";
import { GetBaggedInventoriesReducer } from "./sil/warehouse/baggedInventory/getBaggedInventories/getBaggedInventoriesReducer";
import { AddBaggedInventoryReducer } from "./sil/warehouse/baggedInventory/addBaggedInventory/addBaggedInventoryReducer";
import { UpdateBaggedInventoryReducer } from "./sil/warehouse/baggedInventory/updateBaggedInventory/updateBaggedInventoryReducer";
import { IPSLookup } from "./sil/warehouse/baggedInventory/getSieveLookups/getPSLookupsConstaints";
import { GetPSLookupsReducer } from "./sil/warehouse/baggedInventory/getSieveLookups/getPSLookupsReducer";
import { IComplaintLogging } from "./sil/complaintLogging/getComplaintLogging/getComplaintLoggingConstaints";
import { GetComplaintLoggingReducer } from "./sil/complaintLogging/getComplaintLogging/getComplaintLoggingReducer";
import { UpdateComplaintLoggingReducer } from "./sil/complaintLogging/updateComplaintLogging/updateComplaintLoggingReducer";
import { AddComplaintLoggingReducer } from "./sil/complaintLogging/addComplaintLogging/addComplaintLoggingReducer";
import { IBeltScale } from "./sil/cv/beltScales/getBeltScales/getBeltScalesConstaints";
import { ICrane } from "./sil/cv/cranes/getCranes/getCranesConstaints";
import { ILabScale } from "./sil/cv/labScales/getLabScales/getLabScalesConstaints";
import { INCR } from "./sil/cv/ncrs/getNCRs/getNCRsConstaints";
import { ISieveDryPlant } from "./sil/cv/sieveDryPlant/getSieveDryPlants/getSieveDryPlantsConstaints";
import { ISieveFracBall } from "./sil/cv/sieveFracBall/getSieveFracBalls/getSieveFracBallsConstaints";
import { ISieveWashPlant } from "./sil/cv/sieveWashPlant/getSieveWashPlants/getSieveWashPlantsConstaints";
import { ITruckScale } from "./sil/cv/truckScales/getTruckScales/getTruckScalesConstaints";
import { IWarehouse } from "./sil/cv/warehouses/getWarehouses/getWarehousesConstaints";
import { GetBeltScaleReducer } from "./sil/cv/beltScales/getBeltScales/getBeltScalesReducer";
import { AddBeltScaleReducer } from "./sil/cv/beltScales/addBeltScale/addBeltScaleReducer";
import { UpdateBeltScaleReducer } from "./sil/cv/beltScales/updateBeltScale/updateBeltScaleReducer";
import { GetCraneReducer } from "./sil/cv/cranes/getCranes/getCranesReducer";
import { GetNCRReducer } from "./sil/cv/ncrs/getNCRs/getNCRsReducer";
import { GetSieveDryPlantReducer } from "./sil/cv/sieveDryPlant/getSieveDryPlants/getSieveDryPlantsReducer";
import { GetSieveFracBallReducer } from "./sil/cv/sieveFracBall/getSieveFracBalls/getSieveFracBallsReducer";
import { GetSieveWashPlantReducer } from "./sil/cv/sieveWashPlant/getSieveWashPlants/getSieveWashPlantsReducer";
import { GetTruckScaleReducer } from "./sil/cv/truckScales/getTruckScales/getTruckScalesReducer";
import { GetWarehouseReducer } from "./sil/cv/warehouses/getWarehouses/getWarehousesReducer";
import { AddCraneReducer } from "./sil/cv/cranes/addCrane/addCraneReducer";
import { AddLabScaleReducer } from "./sil/cv/labScales/addLabScale/addLabScaleReducer";
import { UpdateLabScaleReducer } from "./sil/cv/labScales/updateLabScale/updateLabScaleReducer";
import { AddNCRReducer } from "./sil/cv/ncrs/addNCR/addNCRReducer";
import { UpdateNCRReducer } from "./sil/cv/ncrs/updateNCR/updateNCRReducer";
import { AddSieveDryPlantReducer } from "./sil/cv/sieveDryPlant/addSieveDryPlant/addSieveDryPlantReducer";
import { UpdateSieveDryPlantReducer } from "./sil/cv/sieveDryPlant/updateSieveDryPlant/updateSieveDryPlantReducer";
import { AddSieveFracBallReducer } from "./sil/cv/sieveFracBall/addSieveFracBall/addSieveFracBallReducer";
import { UpdateSieveFracBallReducer } from "./sil/cv/sieveFracBall/updateSieveFracBall/updateSieveFracBallReducer";
import { AddSieveWashPlantReducer } from "./sil/cv/sieveWashPlant/addSieveWashPlant/addSieveWashPlantReducer";
import { UpdateSieveWashPlantReducer } from "./sil/cv/sieveWashPlant/updateSieveWashPlant/updateSieveWashPlantReducer";
import { AddTruckScaleReducer } from "./sil/cv/truckScales/addTruckScale/addTruckScaleReducer";
import { UpdateTruckScaleReducer } from "./sil/cv/truckScales/updateTruckScale/updateTruckScaleReducer";
import { AddWarehouseReducer } from "./sil/cv/warehouses/addWarehouse/addWarehouseReducer";
import { UpdateWarehouseReducer } from "./sil/cv/warehouses/updateWarehouse/updateWarehousReducer";
import { UpdateCraneReducer } from "./sil/cv/cranes/updateCrane/updateCraneReducer";
import { GetLabScaleReducer } from "./sil/cv/labScales/getLabScales/getLabScalesReducer";
import { IQualityControlTestings } from "./sil/qualityControlTestings/getQualityControlTestings/getQualityControlTestingsConstaints";
import { GetQualityControlTestingsReducer } from "./sil/qualityControlTestings/getQualityControlTestings/getQualityControlTestingsReducer";
import { AddQualityControlTestingReducer } from "./sil/qualityControlTestings/addQualityControlTesting/addQualityControlTestingReducer";
import { UpdateQualityControlTestingReducer } from "./sil/qualityControlTestings/updateQualityControlTesting/updateQualityControlTestingReducer";
import { GetWashPlantProductionRecordByIdReducer } from "./sil/washPlant/getWashPlantProductionRecordById/getWashPlantProductionRecordByIdReducer";
import { AddGeoBagReducer } from "./sil/dispatch/addGeoBag/addGeoBagReducer";
import { AddTrailerActivityLogReducer } from "./sil/dispatch/addTrailerActivityLog/addTrailerActivityLogReducer";
import { AddTransloadBOLCountReducer } from "./sil/dispatch/addTransloadBOLCount/addTransloadBOLCountReducer";
import { GetGeoBagsReducer } from "./sil/dispatch/getGeoBags/getGeoBagsReducer";
import { GetTrailerActivityLogsReducer } from "./sil/dispatch/getTrailerActivityLogs/getTrailerActivityLogsReducer";
import { GetTransloadBOLCountsReducer } from "./sil/dispatch/getTransloadBOLCounts/getTransloadBOLCountsReducer";
import { UpdateGeoBagReducer } from "./sil/dispatch/updateGeoBag/updateGeoBagReducer";
import { UpdateTrailerActivityLogReducer } from "./sil/dispatch/updateTrailerActivityLog/updateTrailerActivityLogReducer";
import { UpdateTransloadBOLCountReducer } from "./sil/dispatch/updateTransloadBOLCount/updateTransloadBOLCountReducer";
import { ITransloadBOLCount } from "./sil/dispatch/getTransloadBOLCounts/getTransloadBOLCountsConstaints";
import { ITrailerActivityLog } from "./sil/dispatch/getTrailerActivityLogs/getTrailerActivityLogsConstaints";
import { IGeoBag } from "./sil/dispatch/getGeoBags/getGeoBagsConstaints";
import { GetCarrierAuditReducer } from "./sil/carrier/audit/getAudit/getCarrierAuditReducer";
import { UpdateCarrierAuditReducer } from "./sil/carrier/audit/updateAudit/updateAuditReducer";
import { IGetCarrierAudit } from "./sil/carrier/audit/getAudit/getCarrierAuditConstants";

export interface IAction {
  type: any;
  [key: string]: any;
}

export type ReducerMap<T> = { [K in keyof T]: Reducer<T[K], IAction>; };

export type IDispatch = Dispatch<IAction>;

export interface IStore {
  propsense: {
    catapult: {
      crew_scheduling: {
        getCrews: Server<PropsenseAPIResponse<ICrew[]>>;
        getJobs: Server<PropsenseAPIResponse<IJob[]>>;
        getAssignedCrews: Server<PropsenseAPIResponse<IAssignedCrew[]>>;
        saveAssignedCrewsStatus: Server<string>;
      };
    };
    sil: {
      dispatch: {
        addGeoBag: Server<string>;
        addTrailerActivityLog: Server<string>;
        addTransloadBOLCount: Server<string>;
        getGeoBags: Server<SilAPIResponse<IGeoBag[]>>;
        getTrailerActivityLogs: Server<SilAPIResponse<ITrailerActivityLog[]>>;
        getTransloadBOLCounts: Server<SilAPIResponse<ITransloadBOLCount[]>>;
        updateGeoBag: Server<string>;
        updateTrailerActivityLog: Server<string>;
        updateTransloadBOLCount: Server<string>;
      },
      carrier: {
        saveCarrierForm: Server<string>;
        updateCarrierForm: Server<string>;
        getCarriers: Server<SilAPIResponse<ByIdNumber<ICarrier>>>;
        getCarrierEmails: Server<SilAPIResponse<ICarrierEmail[]>>;
        addCarrierEmail: Server<string>;
        updateCarrierEmail: Server<string>;
        getCarrierFleetTrailers: Server<SilAPIResponse<ICarrierFleetTrailer[]>>;
        addCarrierFleetTrailer: Server<string>;
        updateCarrierFleetTrailer: Server<string>;
        getCarrierLookups: Server<SilAPIResponse<ICarrierLookup[]>>;
        getCarrierInfractions: Server<SilAPIResponse<ICarrierInfraction[]>>;
        addCarrierInfraction: Server<string>;
        updateCarrierInfraction: Server<string>;
        GetCarrierAudit: Server<SilAPIResponse<IGetCarrierAudit[]>>;
        UpdateCarrierAudit: Server<SilAPIResponse<string>>;
      },
      dryPlant: {
        getToken: Server<SilAPIResponse<IToken>>;
        getRunMaterials: Server<SilAPIResponse<IRunMaterials[]>>;
        getProductionRecords: Server<SilAPIResponse<ById<IProductionRecords>>>;
        getProductionLookups: Server<SilAPIResponse<IProductionLookups>>;
        getFeedMaterials: Server<SilAPIResponse<IFeedMaterials[]>>;
        getEmployees: Server<SilAPIResponse<IEmployees[]>>;
        getBinAdjustments: Server<SilAPIResponse<IBinAdjustments[]>>;
        addEmployee: Server<string>;
        addFeedMaterial: Server<string>;
        addProduction: Server<string>;
        addRunMaterial: Server<string>;
        addBinAdjustment: Server<string>;
        updateRunMaterial: Server<string>;
        updateProduction: Server<string>;
        updateFeedMaterial: Server<string>;
        updateEmployee: Server<string>;
        updateBinAdjustment: Server<string>;
        productLookups: Server<SilAPIResponse<IProductionLookups>>;
        productionSummary: Server<SilAPIResponse<IProductionSummary[]>>;
      },
      washPlant: {
        getWashPlantRunMaterials: Server<SilAPIResponse<IWashPlantRunMaterials[]>>;
        getWashPlantFeedMaterials: Server<SilAPIResponse<IWashPlantFeedMaterials[]>>;
        getWashPlantBinAdjustments: Server<SilAPIResponse<IWashPlantBinAdjustments[]>>;
        getWashPlantProductionLookups: Server<SilAPIResponse<IWashPlantProductionLookups>>;
        getWashPlantProductionRecords: Server<SilAPIResponse<ById<IWashPlantProductionRecords>>>;
        getWashPlantProductionRecordById: Server<SilAPIResponse<IWashPlantProductionRecords>>;
        addWashPlantFeedMaterial: Server<string>;
        addWashPlantProduction: Server<string>;
        addWashPlantRunMaterial: Server<string>;
        addWashPlantBinAdjustment: Server<string>;
        updateWashPlantRunMaterial: Server<string>;
        updateWashPlantProduction: Server<string>;
        updateWashPlantFeedMaterial: Server<string>;
        updateWashPlantBinAdjustment: Server<string>;
        washPlantProductLookups: Server<SilAPIResponse<IWashPlantProductionLookups>>;
        washPlantProductionSummary: Server<SilAPIResponse<IWashPlantProductionSummary[]>>;
      },
      sieves: {
        getSieveCalibrations: Server<SilAPIResponse<ById<ISieveCalibration>>>;
        getSieveLookups: Server<SilAPIResponse<ISieveLookup>>;
        addSieveCalibration: Server<string>;
        updateSieveCalibration: Server<string>;
        getSieveChecks: Server<SilAPIResponse<ById<ISieveCheck>>>;
        addSieveCheck: Server<string>;
        updateSieveCheck: Server<string>;
        getSieves: Server<SilAPIResponse<ById<ISieve>>>;
        addSieve: Server<string>;
        updateSieve: Server<string>;
        getSieveProducts: Server<SilAPIResponse<ById<ISieveProduct>>>;
        addSieveProduct: Server<string>;
        updateSieveProduct: Server<string>;
      },
      exploration: {
        getChemicalAnalyses: Server<SilAPIResponse<IChemicalAnalyses[]>>;
        addChemicalAnalyses: Server<string>;
        updateChemicalAnalyses: Server<string>;
      }, 
      warehouse : {
        getWarehouseProducts: Server<SilAPIResponse<IWarehouseProducts[]>>;
        addWarehouseProduct: Server<string>;
        updateWarehouseProduct: Server<string>;
        getBaggedInventories: Server<SilAPIResponse<IBaggedInventories[]>>;
        addBaggedInventory: Server<string>;
        updateBaggedInventory: Server<string>;
        getPSLookups: Server<SilAPIResponse<IPSLookup>>;
      },
      complaintLogging: {
        getComplaintLogging: Server<SilAPIResponse<IComplaintLogging[]>>;
        addComplaintLogging: Server<string>;
        updateComplaintLogging: Server<string>;
      },
      cv: {
        getBeltScale: Server<SilAPIResponse<IBeltScale[]>>;
        addBeltScale: Server<string>;
        updateBeltScale: Server<string>;
        getCrane: Server<SilAPIResponse<ICrane[]>>;
        addCrane: Server<string>;
        updateCrane: Server<string>;
        getLabScale: Server<SilAPIResponse<ILabScale[]>>;
        addLabScale: Server<string>;
        updateLabScale: Server<string>;
        getNCR: Server<SilAPIResponse<INCR[]>>;
        addNCR: Server<string>;
        updateNCR: Server<string>;
        getSieveDryPlant: Server<SilAPIResponse<ISieveDryPlant[]>>;
        addSieveDryPlant: Server<string>;
        updateSieveDryPlant: Server<string>;
        getSieveFracBall: Server<SilAPIResponse<ISieveFracBall[]>>;
        addSieveFracBall: Server<string>;
        updateSieveFracBall: Server<string>;
        getSieveWashPlant: Server<SilAPIResponse<ISieveWashPlant[]>>;
        addSieveWashPlant: Server<string>;
        updateSieveWashPlant: Server<string>;
        getTruckScale: Server<SilAPIResponse<ITruckScale[]>>;
        addTruckScale: Server<string>;
        updateTruckScale: Server<string>;
        getWarehouse: Server<SilAPIResponse<IWarehouse[]>>;
        addWarehouse: Server<string>;
        updateWarehouse: Server<string>;
      }
      qualityControlTesting: {
        getQualityControlTestings: Server<SilAPIResponse<IQualityControlTestings[]>>;
        addQualityControlTesting: Server<string>;
        updateQualityControlTesting: Server<string>;      
      }
    };
    YHD: {
      carrier: {
        saveCarrierForm: Server<string>;
        updateCarrierForm: Server<string>;
        getCarriers: Server<YHDAPIResponse<ByIdNumber<IYHDCarrier>>>;
        getCarrierEmails: Server<YHDAPIResponse<IYHDCarrierEmail[]>>;
        addCarrierEmail: Server<string>;
        updateCarrierEmail: Server<string>;
        getCarrierLookups: Server<YHDAPIResponse<IYHDCarrierLookup[]>>;
        getCarrierFleetTruck: Server<YHDAPIResponse<IYHDCarrierFleetTruck[]>>;
        addCarrierFleetTruck: Server<string>;
        updateCarrierFleetTruck: Server<string>;
        getCarrierInfractions: Server<YHDAPIResponse<IYHDCarrierInfractions[]>>;
        addCarrierInfraction: Server<string>;
        updateCarrierInfraction: Server<string>;
      },
    }
  };
};

const reducerMap: ReducerMap<IStore> = {
  propsense: combineReducers({
    catapult: combineReducers({
      crew_scheduling: combineReducers({
        getCrews: GetCrewsReducer,
        getJobs: GetJobsReducer,
        getAssignedCrews: GetAssignedCrewsReducer,
        saveAssignedCrewsStatus: SaveAssignedCrewsReducer,
      }),
    }),
    sil: combineReducers({
      dispatch: combineReducers({
        addGeoBag: AddGeoBagReducer,
        addTrailerActivityLog: AddTrailerActivityLogReducer,
        addTransloadBOLCount: AddTransloadBOLCountReducer,
        getGeoBags: GetGeoBagsReducer,
        getTrailerActivityLogs: GetTrailerActivityLogsReducer,
        getTransloadBOLCounts: GetTransloadBOLCountsReducer,
        updateGeoBag: UpdateGeoBagReducer,
        updateTrailerActivityLog: UpdateTrailerActivityLogReducer,
        updateTransloadBOLCount: UpdateTransloadBOLCountReducer
      }),
      carrier: combineReducers({
        getCarriers: GetCarriersReducer,
        saveCarrierForm: SaveCarrierFormReducer,
        updateCarrierForm: UpdateCarrierFormReducer,
        getCarrierEmails: GetCarrierEmailsReducer,
        addCarrierEmail: AddCarrierEmailReducer,
        updateCarrierEmail: UpdateCarrierEmailReducer,
        getCarrierFleetTrailers: GetCarrierFleetTrailersReducer,
        addCarrierFleetTrailer: AddCarrierFleetTrailerReducer,
        updateCarrierFleetTrailer: UpdateCarrierFleetTrailerReducer,
        getCarrierLookups: GetCarrierLookupsReducer,
        getCarrierInfractions: GetCarrierInfractionsReducer,
        addCarrierInfraction: AddCarrierInfractionReducer,
        updateCarrierInfraction: UpdateCarrierInfractionReducer,
        GetCarrierAudit: GetCarrierAuditReducer,
        UpdateCarrierAudit: UpdateCarrierAuditReducer
      }),
      dryPlant: combineReducers({
        getToken: GetTokenReducer,

        getRunMaterials: GetRunMaterialsReducer,
        getProductionRecords: GetProductionRecordsReducer,
        getProductionLookups: GetProductionLookupsReducer,
        getFeedMaterials: GetFeedMaterialsReducer,
        getEmployees: GetEmployeesReducer,
        getBinAdjustments: GetBinAdjustmentsReducer,
        
        addEmployee: AddEmployeeReducer,
        addFeedMaterial: AddFeedMaterialReducer,
        addProduction: AddProductionReducer,
        addRunMaterial: AddRunMaterialReducer,
        addBinAdjustment: AddBinAdjustmentReducer,

        updateRunMaterial: UpdateRunMaterialReducer,
        updateProduction: UpdateProductionReducer,
        updateFeedMaterial: UpdateFeedMaterialReducer,
        updateEmployee: UpdateEmployeeReducer,
        updateBinAdjustment: UpdateBinAdjustmentReducer,
        productionSummary: GetProductionSummaryReducer,
        productLookups: GetProductLookupsReducer,

      }),
      washPlant: combineReducers({
        getWashPlantRunMaterials: GetWashPlantRunMaterialsReducer,
        getWashPlantProductionRecords: GetWashPlantProductionRecordsReducer,
        getWashPlantProductionRecordById: GetWashPlantProductionRecordByIdReducer,
        getWashPlantProductionLookups: GetWashPlantProductionLookupsReducer,
        getWashPlantFeedMaterials: GetWashPlantFeedMaterialsReducer,
        getWashPlantBinAdjustments: GetWashPlantBinAdjustmentsReducer,
        
        addWashPlantFeedMaterial: AddWashPlantFeedMaterialReducer,
        addWashPlantProduction: AddWashPlantProductionReducer,
        addWashPlantRunMaterial: AddWashPlantRunMaterialReducer,
        addWashPlantBinAdjustment: AddWashPlantBinAdjustmentReducer,

        updateWashPlantRunMaterial: UpdateWashPlantRunMaterialReducer,
        updateWashPlantProduction: UpdateWashPlantProductionReducer,
        updateWashPlantFeedMaterial: UpdateWashPlantFeedMaterialReducer,
        updateWashPlantBinAdjustment: UpdateWashPlantBinAdjustmentReducer,
        washPlantProductionSummary: GetWashPlantProductionSummaryReducer,
        washPlantProductLookups: GetWashPlantProductLookupsReducer
      }),
      sieves: combineReducers({
        getSieveCalibrations: GetSieveCalibrationsReducer,
        getSieveLookups: GetSieveLookupsReducer,
        addSieveCalibration: AddSieveCalibrationReducer,
        updateSieveCalibration: UpdateSieveCalibrationReducer,
        getSieveChecks: GetSieveChecksReducer,
        addSieveCheck: AddSieveCheckReducer,
        updateSieveCheck: UpdateSieveCheckReducer,
        getSieves: GetSievesReducer,
        addSieve: AddSieveReducer,
        updateSieve: UpdateSieveReducer,
        getSieveProducts: GetSieveProductsReducer,
        addSieveProduct: AddSieveProductReducer,
        updateSieveProduct: UpdateSieveProductReducer
      }),
      exploration: combineReducers({
        getChemicalAnalyses: GetChemicalAnalysesReducer,
        addChemicalAnalyses: AddChemicalAnalysesReducer,
        updateChemicalAnalyses: UpdateChemicalAnalysesReducer
      }),
      warehouse: combineReducers({
        getWarehouseProducts: GetWarehouseProductsReducer,
        addWarehouseProduct: AddWarehouseProductReducer,
        updateWarehouseProduct: UpdateWarehouseProductReducer,
        getBaggedInventories: GetBaggedInventoriesReducer,
        addBaggedInventory: AddBaggedInventoryReducer,
        updateBaggedInventory: UpdateBaggedInventoryReducer,
        getPSLookups: GetPSLookupsReducer,
      }),
      complaintLogging: combineReducers({
        getComplaintLogging: GetComplaintLoggingReducer,
        addComplaintLogging: AddComplaintLoggingReducer,
        updateComplaintLogging: UpdateComplaintLoggingReducer
      }),
      cv: combineReducers({
        getBeltScale: GetBeltScaleReducer,
        addBeltScale: AddBeltScaleReducer,
        updateBeltScale: UpdateBeltScaleReducer,
        getCrane: GetCraneReducer,
        addCrane: AddCraneReducer,
        updateCrane: UpdateCraneReducer,
        getLabScale: GetLabScaleReducer,
        addLabScale: AddLabScaleReducer,
        updateLabScale: UpdateLabScaleReducer,
        getNCR: GetNCRReducer,
        addNCR: AddNCRReducer,
        updateNCR: UpdateNCRReducer,
        getSieveDryPlant: GetSieveDryPlantReducer,
        addSieveDryPlant: AddSieveDryPlantReducer,
        updateSieveDryPlant: UpdateSieveDryPlantReducer,
        getSieveFracBall: GetSieveFracBallReducer,
        addSieveFracBall: AddSieveFracBallReducer,
        updateSieveFracBall: UpdateSieveFracBallReducer,
        getSieveWashPlant: GetSieveWashPlantReducer,
        addSieveWashPlant: AddSieveWashPlantReducer,
        updateSieveWashPlant: UpdateSieveWashPlantReducer,
        getTruckScale: GetTruckScaleReducer,
        addTruckScale: AddTruckScaleReducer,
        updateTruckScale: UpdateTruckScaleReducer,
        getWarehouse: GetWarehouseReducer,
        addWarehouse: AddWarehouseReducer,
        updateWarehouse: UpdateWarehouseReducer,
      }),
      qualityControlTesting: combineReducers({
        getQualityControlTestings: GetQualityControlTestingsReducer,
        addQualityControlTesting: AddQualityControlTestingReducer,
        updateQualityControlTesting: UpdateQualityControlTestingReducer
      }),
    }),
    YHD: combineReducers({
      carrier: combineReducers({
        getCarriers: GetYHDCarriersReducer,
        saveCarrierForm: SaveYHDCarrierFormReducer,
        updateCarrierForm: UpdateYHDCarrierFormReducer,
        getCarrierEmails: GetYHDCarrierEmailsReducer,
        addCarrierEmail: AddYHDCarrierEmailReducer,
        updateCarrierEmail: UpdateYHDCarrierEmailReducer,
        getCarrierLookups: GetYHDCarrierLookupsReducer,
        getCarrierFleetTruck: GetYHDCarrierFleetTruckReducer,
        addCarrierFleetTruck: AddYHDCarrierFleetTruckReducer,
        updateCarrierFleetTruck: UpdateYHDCarrierFleetTruckReducer,
        getCarrierInfractions: GetYHDCarrierInfractionsReducer,
        addCarrierInfraction: AddYHDCarrierInfractionReducer,
        updateCarrierInfraction: UpdateYHDCarrierInfractionReducer
      })
    })
  }),
};

export default combineReducers<IStore>(reducerMap);