import jwt_decode from "jwt-decode";
import { RouteComponentProps } from "react-router";
import { IIdName } from "../../utils/sharedTypes";

export const calculateAge = (dateString: string): number => {
  var birthday = +new Date(dateString);
  return ~~((Date.now() - birthday) / (31557600000));
};

export const deleteText = "Are you sure, you want to delete this ?";
export const ZEROTH = 0;
export const ONE = 0;

export const gethourTime = (dt2: Date, dt1: Date): string => {
  let diff = (dt2.getTime() - dt1.getTime()) / 1000;
  diff /= 60;
  let num = Math.abs(Math.round(diff));
  const hours = Math.floor(num / 60);
  const minutes = num % 60;
  // const finHour = (hours + 11) % 12 + 1;
  return `${hours}:${padLeadingZeros(minutes,2)}`;
};

export const gethourOnlyTime = (dt2: Date, dt1: Date): string => {
  let diff = (dt2.getTime() - dt1.getTime()) / 1000;
  diff /= 60;
  let num = Math.abs(Math.round(diff));
  const hours = Math.floor(num / 60);
  const minutes = num % 60;
  const tHour = minutes / 60;
  const totalHours = hours + tHour;
  // const finHour = (hours + 11) % 12 + 1;
  return `${totalHours}`;
};

const padLeadingZeros = (num: number, size: number): string => {
  var s = num+"";
  while (s.length < size) s = "0" + s;
  return s;
}
export const undefinedFunction = (): void => undefined;

const getToken = localStorage.getItem("adal.idtoken");
export const token: any = (getToken !== null && getToken.length > 0) ? jwt_decode(getToken) : { unique_name: "SUREWAYGROUP\\likhit.budwal" };
export const userName = token.unique_name.substr(token.unique_name.lastIndexOf("\\") + 1);

export const monthNames: IIdName<number>[] = [
  { id: 1, name: "JAN" },
  { id: 2, name: "FEB" },
  { id: 3, name: "MAR" },
  { id: 4, name: "APR" },
  { id: 5, name: "MAY" },
  { id: 6, name: "JUN" },
  { id: 7, name: "JUL" },
  { id: 8, name: "AUG" },
  { id: 9, name: "SEP" },
  { id: 10, name: "OCT" },
  { id: 11, name: "NOV" },
  { id: 12, name: "DEC" },
];

export const monthInNumber = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];

export const getYearsList = (startYear: number): IIdName<number>[] => {
  const currentYear = new Date().getFullYear();

  const yearsList: IIdName<number>[] = [];
  for (let year = startYear; year <= currentYear; year++) {
    yearsList.push({ id: year, name: `${year}` });
  }

  return yearsList;
}

export const getDate = (showTime: boolean, value?: string): string => {
  if (value !== "" && value) {

    const date = new Date(value);
    const dateFormat = `${monthInNumber[date.getMonth()]}/${date.getDate()}/${date.getFullYear()}`;

    return dateFormat;
  }
  return "";
};

export interface IQueryString {
  id?: string;
  keyword?: string;
};

interface ICallRouteWithQueryStringProps {
  route: RouteComponentProps;
  pathName: string;
  search: IQueryString;
};

export const callRouteWithQueryString = (props: ICallRouteWithQueryStringProps): void => {
  let search = {};
  if (props.search.id)
    search = { ...search, id: props.search.id };
  if (props.search.keyword)
    search = { ...search, keyword: props.search.keyword };

  props.route.history.push({ pathname: props.pathName, search: "?" + new URLSearchParams(search).toString() });
};

export const StaticToken = "19515195-d571-44a3-b0db-637f5bf24f54";

export const generateRandomText = (length: number): string => {
  let result = "";
  let characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() *
      charactersLength));
  }
  return result;
};

export const addDashesToPhone = (phone: string): string => {
  // eslint-disable-next-line no-useless-escape
  phone = phone.replace("/\D[^\.]/g", "");
  phone = phone.replace(/-/g, "");
  phone = phone.slice(0, 3) + "-" + phone.slice(3, 6) + "-" + phone.slice(6);
  return phone;
};

export const numberAndDashCheck = (value: string): boolean => {
  return /^[0-9-]*$/.test(value);
};

export const numberAndDecimalCheck = (value: string): boolean => {
  return /^\d*\.?\d*$/.test(value);
};

export const numberAndNotDecimalCheck = (value: string): boolean => {
  return /^\d*$/.test(value);
};

export const yesOrNoOptions: IIdName<string>[] = [{ id: "Yes", name: "Yes" }, { id: "No", name: "No" }];

export const methodOfNotification = [
  { id: "Phone", name: "Phone" },
  { id: "Email", name: "Email" },
  { id: "Ticket Issued", name: "Ticket Issued" },
  { id: "Other", name: "Other" }
];

export const infractionStatus = [
  { id: "Pending", name: "Pending" },
  { id: "Closed", name: "Closed" }
];

export const findInfractionNotification = (value: string): any => {
  const findNotificationMethod = methodOfNotification.findIndex(x => x.name === value);
  const notification: any = (value.length > 0) ? findNotificationMethod < 0 ? methodOfNotification[3] : methodOfNotification[findNotificationMethod] : "";

  return notification;
};

export const ComplaintCompanyOptions: IIdName<string>[] = [{ id: "Sil", name: "Sil" }, { id: "YHD", name: "YHD" }];
export const ComplaintPlantOptions: IIdName<string>[] = [{ id: "Bruderheim", name: "Bruderheim" }, { id: "Hillmond", name: "Hillmond" }];

export const hourArray: IIdName<number>[] = [
  { id: 0, name: "0" },
  { id: 1, name: "1" },
  { id: 2, name: "2" },
  { id: 3, name: "3" },
  { id: 4, name: "4" },
  { id: 5, name: "5" },
  { id: 6, name: "6" },
  { id: 7, name: "7" },
  { id: 8, name: "8" },
  { id: 9, name: "9" },
  { id: 10, name: "10" },
  { id: 11, name: "11" },
  { id: 12, name: "12" },
  { id: 13, name: "13" },
  { id: 14, name: "14" },
  { id: 15, name: "15" },
  { id: 16, name: "16" },
  { id: 17, name: "17" },
  { id: 18, name: "18" },
  { id: 19, name: "19" },
  { id: 20, name: "20" },
  { id: 21, name: "21" },
  { id: 22, name: "22" },
  { id: 23, name: "23" }
];

export const minuteArray: IIdName<number>[] = [
  { id: 0, name: "0" },
  { id: 5, name: "5" },
  { id: 10, name: "10" },
  { id: 15, name: "15" },
  { id: 20, name: "20" },
  { id: 25, name: "25" },
  { id: 30, name: "30" },
  { id: 35, name: "35" },
  { id: 40, name: "40" },
  { id: 45, name: "45" },
  { id: 50, name: "50" },
  { id: 55, name: "55" },
  { id: 60, name: "60" }
];