import React, {Component} from "react";
import {allRequests} from './createForm';
import '../../../../shared/simos/styling/gridviewstyles.css'
import {FaPencilAlt} from 'react-icons/fa';
import {allValues} from './orderedBy';
import {filterOrderInfo, filterOrderByStatus} from '../../../../shared/simos/helper/filterOrderInfo';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ProductsOrderedPopup from './productsOrderedPopup';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@material-ui/core/Grid';
import Card from '@mui/material/Card';
import { CardContent } from "@material-ui/core";
import DataGrid, {
    Column, FilterRow, HeaderFilter, 
    Paging, Button, Pager, SearchPanel, 
    FilterPanel,
  FilterBuilderPopup,
  Export,
  } from 'devextreme-react/data-grid';
  import 'devextreme/dist/css/dx.light.css';
  import {formatDateFromDBFormat} from '../../../../shared/simos/helper/dateFormatter';
  import OrderHistoryPage from '../HistoryPages/OrderHistory/orderHistoryPage';
  import {getSIMOSUserGroupAccess} from '../APICalls/getSIMOSGroupAccess';
  import {savedEditView} from './editForm';
  import LoadingPanel from "../../../../shared/simos/helper/loadingIcon";
  import {getSIMOSCustomerInfoByID} from "../APICalls/getRequests/getSIMOSCustomerInfoByID";
import { getSILURLParams } from "../../../../shared/simos/helper/getURLContainsSIL";
import { LAButton, LAIconButton } from "../../../../shared/buttons";
import { DownloadIcon, FileUploadIcon } from "../../../../shared/icons";

interface IProps {
    showGrid:any;
    history:any;
}

interface IState {
    isMouseInside:boolean;
    showEditForm:boolean;
    showCloneForm:boolean;
    offset:number;
    perPage:number;
    currentPage:number;
    all_transports:any[];
    all_locations:any[];
    all_status:string[];
    copydata:any;
    isGridShown:boolean;
    view:string;
    pageSize:number;
    viewProductModal:boolean;
    showOrderView:boolean;
    viewOrderVersionHistory:boolean;
    searchValue:string;
}

const renderTitleHeader = (data:any) => {
    return <p style={{ color:getSILURLParams()?.includes("bru")?"#5383d3":"#a73030",font: 'Cookie', fontSize:'14px'}}>{data.column.caption}</p>
}

const filterBuilderPopupPosition = {
    of: window,
    at: 'top',
    my: 'top',
    offset: { y: 10 },
  };
function StyledComponent(cellData:any):any {
    if(cellData.column.dataField === 'status'){
        switch(cellData.data.status){
            case 'Initiated':
                return <div className={"devex-styled-grid-row-default"}>{cellData.value}</div>;
            case 'Assigned':
                return <div className={"devex-styled-grid-row-default"}>{cellData.value}</div>;
            case 'Ready for pickup':
                return <div className={"devex-styled-grid-row-orange"}>{cellData.value}</div>;
            case 'Completed':
                return <div className={"devex-styled-grid-row-green"}>{cellData.value}</div>;
            case 'Cancelled':
                return <div className={"devex-styled-grid-row-red"}>{cellData.value}</div>;
            case 'Loading':
                return <div className={"devex-styled-grid-row-loading"}>{cellData.value}</div>;
        }
        
    }
    else{
    /* <div className={cellData.data.email === 'admin.test@gmail.com' ? "devex-styled-grid-row-green": cellData.data.email ==='dylan.ollikka@sil.ab.ca' ? "devex-styled-grid-row-red":"devex-styled-grid-row-orange"}>{cellData.value}</div> */
        return (
            <div className={"devex-styled-grid-row-default"}>{cellData.value}</div>
        )
    }
}
var allRequestsCopy;
var optionTransport:any;
var globalid = 0;
var filtered:any;
let allCustomers:any = [];
var copydata:any;
var product_order:any[] = [];
var productLocation: string  = '';
var allReqsView:any;
var oldRequests:any;
var isSuperUser:boolean = false;
var savedView:string = 'none';
var isLoading:boolean = true;

class gridView extends Component<IProps, IState> {
    constructor(props:any) {
        super(props);
        this.state = {
            isMouseInside:false, showEditForm: false, showCloneForm:false, offset:0,perPage:10,currentPage:0,all_transports:[],
            all_locations:['Bulk','Warehouse'], all_status:["Initiated","Assigned","Completed","Cancelled", "Ready for pickup"], copydata:null,isGridShown:false,
            view:'active', pageSize:10, viewProductModal:false, showOrderView:false, viewOrderVersionHistory:false, searchValue: ''
        }
        this.editGridTable = this.editGridTable.bind(this);
        this.cloneGridTable = this.cloneGridTable.bind(this);
        this.openPopup = this.openPopup.bind(this);
        this.viewOrderInfo = this.viewOrderInfo.bind(this);
        this.viewOrderVersionHistory = this.viewOrderVersionHistory.bind(this);
    
    }

    async ExportExcel(view: string, type: string){
        const url = 'https://api2.propsense.com/api/PS/SIMOS/GetSIMOSOrderExcel';
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type':'application/json' },
            body: JSON.stringify({request:{ view: view, type: type }, token: '19515195-d571-44a3-b0db-637f5bf24f54' })
        }

        try {
            const response = await fetch(url, requestOptions);
            if (response.ok) {
                const blob = await response.blob();
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Orders.xlsx');
                document.body.appendChild(link);
                link.click();
                if(link && link.parentNode)
                    link.parentNode.removeChild(link);
            } else {
                console.error('Export failed:', response.statusText);
            }
        } catch (error) {
            console.error('Export failed:', error);
        }
    }


    editGridTable (index: number){
        globalid = index;
        // Create a copy of the current edit in case of a cancel
        allRequestsCopy = JSON.parse(JSON.stringify(allRequests));
        this.props.history.push('/simos/orders/editorder/id='+index +getSILURLParams());
    }

    viewOrderVersionHistory(index:number, data:any){
       // this.props.history.push('/simos/history/orders/id='+index);
       window.open("/simos/history/orders/id="+index+getSILURLParams(), "_blank");
    }

    viewOrderInfo(index:number, data:any){
        //this.setState({showOrderView:true});
        copydata = data;
        this.props.history.push('/simos/orders/vieworder/id='+index+getSILURLParams());
        //return <ViewSIMOSOrderForm history={this.props.history} editID={index} allRequests={data}></ViewSIMOSOrderForm>
    }

    getPDFTicket(id:string){
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ token: '19515195-d571-44a3-b0db-637f5bf24f54', request:{id: id } })
        };
        fetch('https://api2.propsense.com/api/GetSIMOSOrderTicket', requestOptions)
        .then(async response => {
           // const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
            // error response
            if(!response.ok) {
                const error = response.status;
                return Promise.reject(error);
            }          
            
                // Creating new object of PDF file
                response.blob().then(blob => {
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = id+'.pdf';
                alink.click();
                })
        })
        .catch(error => {
            console.log('There was an error!', error);
        });
    }


    cloneGridTable(obj:any) {
        this.setState({showCloneForm:true});
        this.props.history.push('/simos/orders/cloneForm/id='+obj.id);
        copydata = obj;
    }
    
    async fetchOrder() {
        let initialOrders:any = [];
        let initialProducts:any = [];
        const requestOptions = {
             method: 'POST',
             headers: { 'Content-Type':'application/json' },
             body: JSON.stringify({request:{Customer:'orderdesk', Warehouse_Type:getSILURLParams()==="?sil_bru"?"SIL BRU Warehouse":"SIL ED Warehouse"},token: '19515195-d571-44a3-b0db-637f5bf24f54' })
         }
         fetch('https://api2.propsense.com/api/GetSIMOSOrder', requestOptions)
         .then(async response => {
             const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
               // error response
               if(!response.ok) {
                 const error = (data && data.message) || response.status;
                 return Promise.reject(error);
             }
             console.log(data)
             // no error
             //TODO: format order to contain Products OR use the API call for products to display
             for(var i=0;i<data.length;i++) {
          //   await data.map(async (order:any, index:number) => {
              let order = data[i];
                    let time1 = formatDateFromDBFormat(order.pickupdate, order.pickuphour, order.pickupminute).replace("T"," ");
                    let time2 = formatDateFromDBFormat(order.deliverydate, order.deliveryhour, order.deliveryminute).replace("T"," ");
                    if(time2===' NaN:'){
                        time2='N/A'
                    }
                    if(time1 ===' NaN:'){
                        time1='N/A'
                    }
                    // Set exporer code string as empty if undefined
                    let explorer_code_string:string = '';
                    if(order.explorer_Code===null){
                        explorer_code_string='';
                    }
                    else{
                        explorer_code_string = "("+order.explorer_Code+")"
                    }
                    initialOrders.push({id:order.id, customer: order.customer+" "+explorer_code_string, delivery:order.address, pickupDate: time1, pickupHour:order.pickuphour, 
                     pickupMin:order.pickupminute, transCompany:order.transport_Company,contacts:allValues.orderedby,siteContact:order.sitecontact,siteContactPhone:order.sitecontact_Phone,
                     poNum:order.pO_Number,emailUser:order.emailuser,emailTransport:order.emailtransport,truckRequired:order.truck_Required,deliveryDate:time2,
                     deliveryHour:order.deliveryhour,deliveryMin:order.deliveryminute,products:[],location:order.location,
                     comments:order.comments,packaged:order.packaged,completed:order.completed,
                     cancelled:order.cancelled,
                     truckNumber:order.truck_Number,
                     trailerNumber:order.trailer_Number,
                     tareWeight:order.tare_Weight,
                     grossWeight:order.gross_Weight, customer_ID:order.customer_ID, pickup:order.pickup, status:order.status, order_Type:order.order_Type});
                       // Add possible filters to each dropdown
                    if(allCustomers.indexOf(order.customer) === -1 ){
                        allCustomers.push(order.customer);
                    }
                // });
             }
         console.log(initialOrders)
     
     // or use allRequests.pop() allRequests.push(initialOrders) 
     allRequests[0] = initialOrders.reverse();
        
                     
     // oldRequest used for searching entire data set
      oldRequests = allRequests[0];
      // used for displaying 
      allReqsView = allRequests[0];
      // filter original
      allReqsView = filterOrderByStatus(oldRequests,this.state.view, savedView);
      // Auto renders the grid
     isLoading = false;
     this.setState({isGridShown:true});
         
               // }
        //      }
        //    }
        //  });
        });
        return true;
       }
    async componentDidMount() {
        if(savedEditView){
            savedView = savedEditView;
        }
        else{
            savedView = "none";
        }
        var response:any = await getSIMOSUserGroupAccess();
        var userRole:any = response.role;
        isSuperUser = userRole === 'SuperAdmin';
      // Obtains all transport companies from the db
      let allTransports:string[] = [];
      const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ token: '19515195-d571-44a3-b0db-637f5bf24f54' })
      };
      fetch('https://api2.propsense.com/api/GetSIMOSTransportCompany', requestOptions)
      .then(async response => {
          const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
          // error response
          if(!response.ok) {
              const error = (data && data.message) || response.status;
              return Promise.reject(error);
          }
          // no error
          data.map((company_name:any) => {
              allTransports.push(company_name.transport_Company);
          });
          //this.setState({all_transports: allTransports});
          optionTransport = allTransports;
      })
      .catch(error => {
          console.log('There was an error!', error);
      });
        // Obtain every order from db
        this.fetchOrder();
    }
    setPageSize(newPageSize:number){
        this.setState({pageSize: newPageSize});
    }
    setSearchResult(newSearch:any){
        allReqsView = newSearch;
    }
    async openPopup(order_ID:number, location?:any){
        product_order=[];
        productLocation = location;
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type':'application/json' },
            body: JSON.stringify({request:{Order_ID:order_ID},token: '19515195-d571-44a3-b0db-637f5bf24f54' })
        }
        var initialProducts:any[] = [];
        await fetch('https://api2.propsense.com/api/GetSIMOSProductsByOrderID', requestOptions)
        .then(async response => {
          const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
          // error response
          if(!response.ok) {
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
          }
          data.map((products:any, idx:number) => {
            initialProducts.push(products);
          });
         // for(var idx=0;idx<(allRequests[0].length); idx++){
          for (var index=0;index<initialProducts.length;index++){
                    // if(initialProducts[index].orderID === allRequests[0][idx].id) {
                    //     allRequests[0][idx].products.push({code: initialProducts[index].code, chosenPer:initialProducts[index].per, 
                    //     c_w: initialProducts[index].cw, chosenProd: initialProducts[index].product, quantity: initialProducts[index].quantity, chosenSize: 
                    //     initialProducts[index].size, status: initialProducts[index].status});
                    //     product_order = allRequests[0][idx].products;
                    // }
            //    }
            product_order.push({chosenSize:initialProducts[index].size, chosenPer:initialProducts[index].per, chosenProd:initialProducts[index].product,code:initialProducts[index].code,
            c_w:initialProducts[index].cw,d_c_w:initialProducts[index].double_CW, quantity:initialProducts[index].quantity, batch:initialProducts[index].batch});
            }
        });
        //product_order = initialProducts;
        this.setState({viewProductModal:true});
    }
    closePopup(){
        this.setState({viewProductModal:false});
    }
    // Switch view and filter results
    handleViewChange(eventKey:any){console.log('sil ed warehouse', eventKey.target.value)
        switch(eventKey.target.value) {
         case 'active':
             this.setState({view:'active'});
             allReqsView = filterOrderByStatus(oldRequests,'active',savedView);
             break;
         case 'completed':
             this.setState({view:'completed'});
             allReqsView = filterOrderByStatus(oldRequests,'completed', savedView);
             break;
        case 'bulk':
            savedView = 'bulk';
            this.setState({});
            allReqsView = filterOrderByStatus(oldRequests,this.state.view, 'bulk');
            break;
        case 'sil_bru_warehouse':
            savedView = 'sil_bru_warehouse';
            this.setState({});
            allReqsView = filterOrderByStatus(oldRequests,this.state.view, 'sil_bru_warehouse');
            break;
        case 'sil_ed_warehouse':
            savedView = 'sil_ed_warehouse';
            this.setState({});
            allReqsView = filterOrderByStatus(oldRequests,this.state.view, 'sil_ed_warehouse');
            break;
        case 'none':
            savedView = 'none';
            this.setState({});
            allReqsView = filterOrderByStatus(oldRequests,this.state.view, 'none');
            break;
        }
    }

    handleValueChange = (e:any) => {
        // console.log(e);
        const trimmedValue = e.trim();
        this.setState({searchValue: trimmedValue});
      };

    render() {
        console.log(allReqsView)
        //if(this.state.isGridShown===true){
            // Change the items shown based on the view selected
            allReqsView = filterOrderByStatus(allReqsView, this.state.view, savedView);
        return (
            <>  
            <Grid container spacing={1} direction="row">
                <Card sx={{display:'flex', flexDirection:'row', height:'75px'}} variant="outlined">
         {/* <CardContent>
         <FormLabel style={{fontWeight:'bold'}} component="legend">Order Status</FormLabel>
                <RadioGroup row defaultValue={this.state.view} onChange={this.handleViewChange.bind(this)}>
                    <FormControlLabel value="active" control={<Radio size="small" />} label="Assigned/Initiated/Ready for Pickup"/>
                    <FormControlLabel value="completed" control={<Radio size="small"/>} label="All"/>
                </RadioGroup></CardContent>      */}
                <CardContent >
            <FormLabel style={{fontWeight:'bold'}} component="legend">View</FormLabel>
                <RadioGroup row defaultValue={savedView} onChange={this.handleViewChange.bind(this)}>
                <FormControlLabel value="none" control={<Radio size="small"/>} label="All"/>
                {window.location.href.includes("sil_ed") ? 
                    <FormControlLabel value="sil_ed_warehouse" control={<Radio size="small" />} label="SIL Ed Warehouse"/>:
                    
                    <FormControlLabel value="sil_bru_warehouse" control={<Radio size="small" />} label="SIL Bru Warehouse"/>}
                    <FormControlLabel value="bulk" control={<Radio size="small"/>} label="Bulk"/>
                </RadioGroup>
                </CardContent>
                <LAIconButton label="Export" icon={<DownloadIcon />} onClick={() => this.ExportExcel(savedView, window.location.href.includes("sil_ed") ? "sil_ed" : "sil_bru")} />
                </Card>
            {/* <SearchBar setSearchResult={this.setSearchResult.bind(this)} searchArray={oldRequests} filterBy={['id','customer','poNum','pickupDate','deliveryDate','location']} showGrid={this.props.showGrid}></SearchBar> */}
                </Grid>
                <br></br>
                <Grid container spacing={1}  direction="column">
                    {/* Removed width:700 in div and width:700 in DataGrid */}
            <div style={{  width:'100%'}}>
            {/* All order view */}
            {savedView === 'none' ? 
            isLoading ? <LoadingPanel />:
            <DataGrid id={'gridContainer'}
                focusedRowEnabled={true}
                hoverStateEnabled={true}
                rowAlternationEnabled={true}
                dataSource={allReqsView}
                keyExpr="id"
                showBorders={true}
                wordWrapEnabled={true}
                height={1300}
            > 
                <SearchPanel visible={true}
                width={340}
                placeholder="Search for..." 
                text={this.state.searchValue}
                onTextChange={this.handleValueChange}
                />    
                <Paging enabled={true} defaultPageSize={20}/>
                <Pager
                    visible={true}
                    allowedPageSizes={[20,40,80]}
                    displayMode={'full'}
                    showPageSizeSelector={true}
                    showInfo={true}
                    showNavigationButtons={true} />
                <FilterRow visible={true} />
                <FilterPanel visible={true} />
                <FilterBuilderPopup position={filterBuilderPopupPosition} />
                <HeaderFilter visible={true} allowSearch={true} />
                <Column type="buttons" caption="Edit" headerCellRender={renderTitleHeader}>
                    <Button text="edit" visible={true} onClick={(e:any) =>  {this.editGridTable(e.row.data.id)}}><FaPencilAlt/></Button>
                </Column>
                {/* <Column type="buttons" caption="Clone" headerCellRender={renderTitleHeader}>
                    <Button text="clone" visible={true} onClick={(e:any) =>  {this.cloneGridTable(e.row.data)}}><FaClone/></Button>
                </Column> */}
                {/* <Column dataField="id" type="buttons" caption="Order #" alignment="left" width={100} cellRender={(cellData:any) => {
                    return  <button onClick={(e:any) => {this.viewOrderInfo(cellData.row.data.id, cellData.row.data)}} style={{color:'blue', font:'Cookie, Arial, Helvetica, sans-serif', fontSize:'14px', border:'none', backgroundColor:'transparent', borderBottom:'blue solid 1px'}}>{cellData.row.data.id}</button>}} 
                    headerCellRender={renderTitleHeader}>
                </Column> */}
                <Column dataField="id" caption="Order #" alignment="left" cellRender={StyledComponent} headerCellRender={renderTitleHeader} sortOrder={"desc"}/>
                <Column dataField="id" type="buttons" caption="Batch# Summary" alignment="left" width={130} cellRender={(cellData:any) => {
                    return  <button onClick={(e:any) => {this.getPDFTicket(cellData.row.data.id)}} style={{color:'blue', font:'Cookie, Arial, Helvetica, sans-serif', fontSize:'14px', border:'none', backgroundColor:'transparent', borderBottom:'blue solid 1px'}}>pdf</button>}} 
                    headerCellRender={renderTitleHeader}>
                </Column>
                <Column dataField="customer" caption="Customer" alignment="left" cellRender={StyledComponent} headerCellRender={renderTitleHeader}/>
                <Column dataField="poNum" caption="PO #" alignment="left" width={120} cellRender={StyledComponent} headerCellRender={renderTitleHeader}/>
                <Column dataField="pickupDate" format="M-d-yyyy, HH:mm" caption="Scheduled Pickup Date 5" alignment="left" width={200} cellRender={StyledComponent} headerCellRender={renderTitleHeader}/>
                <Column dataField="deliveryDate" format="M-d-yyyy, HH:mm" caption="Delivery Date" alignment="left" width={200} cellRender={StyledComponent} headerCellRender={renderTitleHeader}/>
                <Column dataField="transCompany" caption="Transport Company" alignment="left" width={200} cellRender={StyledComponent} headerCellRender={renderTitleHeader}/>
                 <Column type="buttons" caption="Products" width={100} headerCellRender={renderTitleHeader}>
                    <Button cssClass="back-btn" onClick={(e:any) => {this.openPopup(e.row.data.id, e.row.data.location)}}>View</Button>
                </Column>
                {/* <Column dataField="comments" caption="Comments" alignment="left" cellRender={StyledComponent} headerCellRender={renderTitleHeader}/> */}
                <Column dataField="status" caption="Status" alignment="left" width={200} cellRender={StyledComponent} headerCellRender={renderTitleHeader}/>
                {isSuperUser ? <Column type="buttons" caption="Version History" width={120} headerCellRender={renderTitleHeader}>
                    <Button cssClass="back-btn" onClick={(e:any) => {this.viewOrderVersionHistory(e.row.data.id, e.row.data)}}>View</Button>
                </Column>:null}
                {/* <Column dataField="location" caption="Location" alignment="left" width={150} cellRender={StyledComponent} headerCellRender={renderTitleHeader}/> */}
            </DataGrid>
            : 
            // Warehouse order view
            savedView === 'sil_bru_warehouse' || savedView==='sil_ed_warehouse' ?
            isLoading ? <LoadingPanel />:
            <DataGrid id={'gridContainer'}
                focusedRowEnabled={true}
                hoverStateEnabled={true}
                rowAlternationEnabled={true}
                dataSource={allReqsView}
                keyExpr="id"
                showBorders={true}
                wordWrapEnabled={true}
                height={1000}
            > 
                <SearchPanel visible={true}
                width={340}
                placeholder="Search for..." 
                text={this.state.searchValue}
                onTextChange={this.handleValueChange}
                />    
                <Paging enabled={true} defaultPageSize={20}/>
                <Pager
                    visible={true}
                    allowedPageSizes={[20,40,80]}
                    displayMode={'full'}
                    showPageSizeSelector={true}
                    showInfo={true}
                    showNavigationButtons={true} />
                <FilterRow visible={true} />
                <FilterPanel visible={true} />
                <FilterBuilderPopup position={filterBuilderPopupPosition} />
                <HeaderFilter visible={true} allowSearch={true} />
                <Export enabled={true} allowExportSelectedData={true} />
                <Column type="buttons" caption="Edit" headerCellRender={renderTitleHeader}>
                    <Button text="edit" visible={true} onClick={(e:any) =>  {this.editGridTable(e.row.data.id)}}><FaPencilAlt/></Button>
                </Column>
                {/* <Column dataField="id" type="buttons" caption="Order #" alignment="left" width={100} cellRender={(cellData:any) => {
                    return  <button onClick={(e:any) => {this.viewOrderInfo(cellData.row.data.id, cellData.row.data)}} style={{color:'blue', font:'Cookie, Arial, Helvetica, sans-serif', fontSize:'14px', border:'none', backgroundColor:'transparent', borderBottom:'blue solid 1px'}}>{cellData.row.data.id}</button>}} 
                    headerCellRender={renderTitleHeader}>
                </Column> */}
                <Column dataField="id" caption="Order #" alignment="left" cellRender={StyledComponent} headerCellRender={renderTitleHeader} sortOrder={"desc"}/>
                <Column dataField="customer" caption="Customer" alignment="left" width={200} cellRender={StyledComponent} headerCellRender={renderTitleHeader}/>
                <Column dataField="poNum" caption="PO #" alignment="left" width={120} cellRender={StyledComponent} headerCellRender={renderTitleHeader}/>
                <Column dataField="pickupDate" format="M-d-yyyy, HH:mm" caption="Scheduled Pickup Date 4" alignment="left" width={200} cellRender={StyledComponent} headerCellRender={renderTitleHeader}/>
                {/* <Column dataField="deliveryDate" format="M-d-yyyy, HH:mm" caption="Delivery Date" alignment="left" width={200} cellRender={StyledComponent} headerCellRender={renderTitleHeader}/> */}
                <Column dataField="transCompany" caption="Transport Company" alignment="left" width={200} cellRender={StyledComponent} headerCellRender={renderTitleHeader}/>
                <Column type="buttons" dataField="products" caption="Products" width={100} headerCellRender={renderTitleHeader}>
                    <Button cssClass="back-btn" onClick={(e:any) => {this.openPopup(e.row.data.id, e.row.data.location)}}>View</Button>
                </Column>
                <Column dataField="comments" caption="Comments" alignment="left" cellRender={StyledComponent} headerCellRender={renderTitleHeader}/>
                {/* <Column dataField="location" caption="Location" alignment="left" width={150} cellRender={StyledComponent} headerCellRender={renderTitleHeader}/> */}
                <Column dataField="status" caption="Status" alignment="left" width={200} cellRender={StyledComponent} headerCellRender={renderTitleHeader}/>
                {isSuperUser ? <Column caption="Version History" type="buttons" width={120} headerCellRender={renderTitleHeader}>
                    <Button cssClass="back-btn" onClick={(e:any) => {this.viewOrderVersionHistory(e.row.data.id, e.row.data)}}>View</Button>
                </Column>:null}
            </DataGrid>
            :
            // Bulk order view
            isLoading ? <LoadingPanel />:
            <DataGrid id={'gridContainer'}
                focusedRowEnabled={true}
                hoverStateEnabled={true}
                rowAlternationEnabled={true}
                dataSource={allReqsView}
                keyExpr="id"
                showBorders={true}
                wordWrapEnabled={true}
                height={1000}
            > 
                <SearchPanel visible={true}
                width={340}
                placeholder="Search for..." 
                text={this.state.searchValue}
                onTextChange={this.handleValueChange}
                />    
                <Paging enabled={true} defaultPageSize={20}/>
                <Pager
                    visible={true}
                    allowedPageSizes={[20,40,80]}
                    displayMode={'full'}
                    showPageSizeSelector={true}
                    showInfo={true}
                    showNavigationButtons={true} />
                <FilterRow visible={true} />
                <FilterPanel visible={true} />
                <FilterBuilderPopup position={filterBuilderPopupPosition} />
                <HeaderFilter visible={true} allowSearch={true} />
                <Export enabled={true} allowExportSelectedData={true} />
                <Column type="buttons" caption="Edit" headerCellRender={renderTitleHeader}>
                    <Button text="edit" visible={true} onClick={(e:any) =>  {this.editGridTable(e.row.data.id)}}><FaPencilAlt/></Button>
                </Column>
                {/* <Column dataField="id" type="buttons" caption="Order #" alignment="left" width={100} cellRender={(cellData:any) => {
                    return  <button onClick={(e:any) => {this.viewOrderInfo(cellData.row.data.id, cellData.row.data)}} style={{color:'blue', font:'Cookie, Arial, Helvetica, sans-serif', fontSize:'14px', border:'none', backgroundColor:'transparent', borderBottom:'blue solid 1px'}}>{cellData.row.data.id}</button>}} 
                    headerCellRender={renderTitleHeader}>
                </Column> */}
                <Column dataField="id" caption="Order #" alignment="left" cellRender={StyledComponent} headerCellRender={renderTitleHeader} sortOrder={"desc"}/>
                <Column dataField="id" type="buttons" caption="pdf" alignment="left" width={100} cellRender={(cellData:any) => {
                    return  <button onClick={(e:any) => {this.getPDFTicket(cellData.row.data.id)}} style={{color:'blue', font:'Cookie, Arial, Helvetica, sans-serif', fontSize:'14px', border:'none', backgroundColor:'transparent', borderBottom:'blue solid 1px'}}>{cellData.row.data.id}</button>}} 
                    headerCellRender={renderTitleHeader}>
                </Column>
                <Column dataField="customer" caption="Customer" alignment="left" width={200} cellRender={StyledComponent} headerCellRender={renderTitleHeader}/>
                <Column dataField="poNum" caption="PO #" alignment="left" width={120} cellRender={StyledComponent} headerCellRender={renderTitleHeader}/>
                <Column dataField="pickupDate" format="M-d-yyyy, HH:mm" caption="Scheduled Pickup Date 3" alignment="left" width={200} cellRender={StyledComponent} headerCellRender={renderTitleHeader}/>
                {/* <Column dataField="deliveryDate" format="M-d-yyyy, HH:mm" caption="Delivery Date" alignment="left" width={200} cellRender={StyledComponent} headerCellRender={renderTitleHeader}/> */}
                <Column dataField="transCompany" caption="Transport Company" alignment="left" width={200} cellRender={StyledComponent} headerCellRender={renderTitleHeader}/>
                <Column type="buttons" dataField="products" caption="Products" width={100} headerCellRender={renderTitleHeader}>
                    <Button cssClass="back-btn" onClick={(e:any) => {this.openPopup(e.row.data.id, e.row.data.location)}}>View</Button>
                </Column>
                <Column dataField="comments" caption="Comments" alignment="left" cellRender={StyledComponent} headerCellRender={renderTitleHeader}/>
                {/* <Column dataField="location" caption="Location" alignment="left" width={150} cellRender={StyledComponent} headerCellRender={renderTitleHeader}/> */}
                <Column dataField="status" caption="Status" alignment="left" width={200} cellRender={StyledComponent} headerCellRender={renderTitleHeader}/>
                {isSuperUser ? <Column caption="Version History" type="buttons" width={120} headerCellRender={renderTitleHeader}>
                    <Button cssClass="back-btn" onClick={(e:any) => {this.viewOrderVersionHistory(e.row.data.id, e.row.data)}}>View</Button>
                </Column>:null}
            </DataGrid>
            }
                {this.state.viewProductModal ? <ProductsOrderedPopup products={product_order} productLocation={productLocation}closePopup={this.closePopup.bind(this)}></ProductsOrderedPopup>:null}
                {this.state.viewOrderVersionHistory? <OrderHistoryPage history={this.props.history}></OrderHistoryPage>:null}
           </div>
           </Grid>
            </>
        );
                    // }else{
                    //     return null
                    // }
    }
}
export default gridView;
export {globalid};
export {allRequestsCopy};
export {optionTransport};
export {filtered};
export {copydata};
export {savedView};