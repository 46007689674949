import React from 'react';
import '../../../../shared/simos/styling/createFormPage.css';
import NavigationMenu from '../../../../shared/simos/helper/NavigationMenu';
import {IProps} from '../SIMOSOrderPage';
import ProductTable from './productTable';
import CreateProductForm from './CreateProductForm';
import { getSIMOSUserGroupAccess } from '../APICalls/getSIMOSGroupAccess';
import urlContainsSILParameter, { getSILURLParams, redirectSIMOSHome } from '../../../../shared/simos/helper/getURLContainsSIL';


interface IState {
    showCreateProductForm:boolean;
    refreshGrid:boolean;
}
class CustomerPage extends React.Component<IProps, IState> {
    constructor(props:IProps) {
        super(props);
        this.state = {
            showCreateProductForm: false, refreshGrid:false
        }
    }

    handleRoute (eventKey:string){
        switch(eventKey) {
            case 'Home':
             this.props.history.push('/simos/orders' + getSILURLParams());
             break;
         case 'CustomerContact':
             this.props.history.push('/simos/viewCustomerContacts' + getSILURLParams());
             break;
        case 'TransportCompany':
            this.props.history.push('/simos/transport-companies' + getSILURLParams());
            break;
         case 'Products':
             this.props.history.push('/simos/products' + getSILURLParams());
             break;
         case 'Customers':
            this.props.history.push('/simos/customers' + getSILURLParams());
            break;
        }
    }
    createNewForm() {
        this.props.history.push('/simos/products/create' + getSILURLParams());
    }
    refreshGrid(){
        this.setState({refreshGrid:true});
        window.scrollTo({behavior:'smooth',top:0});
    }
    handleLogout(){
        // Auth.logout();
        this.props.history.replace('/simos');
    }
    async componentWillMount(){
        var response:any = await getSIMOSUserGroupAccess();
        var userRole:any = response.role;
        if(userRole!='SuperAdmin'){
            this.props.history.push('/simos');
        }
        if(!urlContainsSILParameter()){
            redirectSIMOSHome();
        }
    }

    render() {
        return <div>
            <header className={getSILURLParams()?.includes("bru")?"header-fixed":"header-fixed-sil-ed"}> 
                <NavigationMenu handleRoute={this.handleRoute.bind(this)}></NavigationMenu>
                <div className="header-limiter"> 
                    <h2>SIMOS<span> Products</span>
                <button type='submit' id={getSILURLParams()?.includes("bru")?"create-form-btn":"create-form-btn-sil-ed"} onClick={this.createNewForm.bind(this)}>Create New</button>
                {/* <button type="submit" id="logout-btn" onClick={this.handleLogout.bind(this)}>Home</button> */}
                </h2>
                </div>
                {this.state.showCreateProductForm? <CreateProductForm history={this.props.history}></CreateProductForm>:null}
            </header>
            <div className={getSILURLParams()?.includes("bru")?"data-grid-fixed":"data-grid-fixed-sil-ed"}>
                <ProductTable refreshGrid={this.refreshGrid.bind(this)} history={this.props.history}></ProductTable>
            </div>
        </div>
    }
}

export default CustomerPage;