import React from 'react';
import { IProps } from '../SIMOSOrderPage';
import NavigationMenu from '../../../../shared/simos/helper/NavigationMenu';
import CreateTransportCompanyForm from './createTransportCompanyForm';
import AuthenticateLogin from '../LoginPage/AuthenticateLogin';
import TransportCompanyTable from './transportCompanyTable';
import {getSIMOSUserGroupAccess} from '../APICalls/getSIMOSGroupAccess';
import urlContainsSILParameter, { getSILURLParams, redirectSIMOSHome } from '../../../../shared/simos/helper/getURLContainsSIL';

interface IState {
    showCreateCustomer:boolean;
    customer:string[];
}

export default class TransportCompanyPage extends React.Component<IProps,IState>{
    constructor(props:any) {
        super(props);
        this.state = {showCreateCustomer:false,customer:[]}
    }

    handleRoute (eventKey:string){
        switch(eventKey) {
            case 'Home':
             this.props.history.push('/simos/orders' + getSILURLParams());
             break;
         case 'CustomerContact':
             this.props.history.push('/simos/viewCustomerContacts' + getSILURLParams());
             break;
        case 'Customers':
            this.props.history.push('/simos/customers' + getSILURLParams());
            break;
         case 'TransportCompany':
              this.props.history.push('/simos/transport-companies' + getSILURLParams());
             break;
        case 'Products':
            this.props.history.push('/simos/products' + getSILURLParams());
            break;
        }
    }

    handleLogout(){
        //Auth.logout();
        this.props.history.replace('/simos');
    }
    createNewCustomer() {
        this.props.history.push('/simos/transport-companies/create-transport-company'+getSILURLParams());
        return <CreateTransportCompanyForm history={this.props.history}></CreateTransportCompanyForm>
    }
    async componentWillMount(){
        var response:any = await getSIMOSUserGroupAccess();
        var userRole:any = response.role;
        if(userRole!='Admin' && userRole!='Dispatcher' && userRole!='SuperAdmin'){
            this.props.history.push('/simos');
        }
        if(!urlContainsSILParameter()){
            redirectSIMOSHome();
        }
    }

    render() {
        return (
            <div>
            <header className={getSILURLParams()?.includes("bru")?"header-fixed":"header-fixed-sil-ed"}> 
                <NavigationMenu handleRoute={this.handleRoute.bind(this)}></NavigationMenu>
                <div className="header-limiter"> 
                    <h2>SIMOS<span> Transport Companies</span><button type='submit' id={getSILURLParams()?.includes("bru")?"create-form-btn":"create-form-btn-sil-ed"} onClick={this.createNewCustomer.bind(this)}>Create New</button>
                    </h2>
                </div>
            </header>
            <div className={getSILURLParams()?.includes("bru")?"data-grid-fixed":"data-grid-fixed-sil-ed"}>
                <TransportCompanyTable history={this.props.history}></TransportCompanyTable>
            </div>
            </div>
        );
    }
}